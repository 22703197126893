<div class="bottomSheetContainer">
	<div class="button" (click)="dismiss()">
        <div class="btn-ln">
            <div class="line">

            </div>
            <h4>Cambiar Forma de envío</h4>
        </div>
    </div>
    <div class="formaEnvio">
        <select name="formEnvio" (change)="checkFormaEnvio($event.target.value)" *ngIf="FormasEnvio" >
            <option [value]="formEnvio | json" [selected]="isActiveFormaEnvio(formEnvio)" *ngFor="let formEnvio of FormasEnvio">{{formEnvio.nombre}} ( {{ formEnvio.coste | currency:'EUR' }} )</option>
        </select>

        <div class="footer">
            <button [ngClass]="{'disabled': isLoading}" (click)="updateShipmentForm()" [disabled]="isLoading">Actualizar</button>
        </div>
    </div>
</div>