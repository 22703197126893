// tslint:disable: max-line-length
// tslint:disable: triple-equals

import { AfterContentChecked, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, NgZone, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BetService } from '@modules/games/bet.service';
import { IToast } from 'src/app/interfaces/toast.interface';

const changeToSimpleBet = '¡Atención! Cambio a apuesta simple';
const changeToMultiple44 = 'Apuesta múltiple de 44.';
const changeToMultipleBet = '¡Atención! Cambio a apuesta múltiple';
const limitMaxBet = 'Ha llegado al límite de apuesta.';

import { GamesApiservice } from '@api/services/games/games-api.service';
import { EGamesId } from '@enums/common/games.enum';
import { ManualPlayLotteryComponent } from '../manual-play-lottery.component';
import { AddAlertComponent } from '@modules/games/components/alert/add-alert/add-alert.component';
import { DialogService } from '@api/services/dialog.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { formsEnvioModel } from '@models/api-models/fromsEnvio.model';
import { take, debounceTime, filter, map, pairwise, throttleTime } from 'rxjs/operators';
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { CustomVirtualScroll } from '@classes/CustomVritualScoll';
import * as moment from 'moment';

@Component({
	selector: 'app-manual-play-lottery-mobile',
	templateUrl: './manual-play-lottery-mobile.component.html',
	styleUrls: ['./manual-play-lottery-mobile.component.scss'],
	providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScroll }]
})
export class ManualPlayLotteryMobileComponent extends ManualPlayLotteryComponent implements AfterViewInit, OnInit, OnDestroy, AfterContentChecked {

	public toast: IToast;
	public gameNumbers: any;
	public showNumbers: any;
	public url: string = 'http://www.loteriaelnegrito.com/uploads/imagenes/loterianavidad/emails/202307/emails-102mail.jpg';
	public date: string;
	private drawDate: string;
	public bets: any[];
	public numBuscado: string;
	public searchNumbers: any;
	public load: Boolean;
	public precio: number;
	private coste: number = 0;
	private grupo_id = 0;
	private nombreGrupo = "";
	private ejecute = true;
	private timeout = null;
	public spinnerBottomLoadVisibility = false;


	public lastPage = false

	public FormasEnvio: formsEnvioModel[];

	public numbers = [11111, 22222, 33333, 44444, 55555, 66666, 77777, 88888, 99999, 12345, 12345,];

	public image = '';

	// WHEELS VARIABLES
	public x = [5, 6, 7, 8, 9, '*', 0, 1, 2, 3, 4,];
	public displayedWheels = [[...this.x], [...this.x], [...this.x], [...this.x], [...this.x]]
	public animationClasses = new Array(this.displayedWheels.length).fill('');
	public selectedNumbers = new Array(this.displayedWheels.length).fill('*'); // Initialize with '*'
	public initialState = true;

	// CATEGORY FILTER VARIABLES
	public selectedOption: string = '';
	public combinedNumbers = '*****';
	public isCapicua = false;
	public amountFilter = 1;
	private intervalId: any;
	private currentInterval: number = 200; // Initial interval time in ms

	// SEARCH RESULTS VARIABLES
	public searchResults: any[] = [];
	private page: number = 1;
	private loading: boolean = false;
	private noMoreResults: boolean = false;
	private debounceTimeout: any;
	private isFetching: boolean = false;


	@Input() gameId: EGamesId;
	@ViewChild("search") searchBar: ElementRef;
	@ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

	numberForm = new FormGroup({
		num: new FormControl('')
	});

	constructor(

		public dialog: MatDialog,
		private betService: BetService,
		private gamesApiService: GamesApiservice,
		private dialogService: DialogService,
		private router: Router,
		private ngZone: NgZone,
		private activeRouter: ActivatedRoute,
		@Inject(MAT_DIALOG_DATA) public data: any,

	) {

		super();

	}

	ngOnInit() {

		this.options = this.betService.options;
		this.showNumbers = [];
		this.gameNumbers = [];
		this.numBuscado = "";
		this.searchNumbers = [];
		this.date = "";
		this.bets = [];
		this.load = false;
		this.FormasEnvio = [];
		this.date = this.betService.date;
		this.drawDate = this.date
		this.loadGroup()

		if (this.activeRouter.snapshot.queryParams.date && this.activeRouter.snapshot.queryParams.numero)
			this.date = this.activeRouter.snapshot.queryParams.date

		this.gamesApiService.sorteosAvanzadoObservable$.subscribe(res => {
			if (this.gameId == EGamesId.LOTERIA_DE_NAVIDAD || this.gameId == EGamesId.LOTERIA_DE_EL_NIÑO) {
				const game = res.listadoEspecial.find((game: any) => game.juego_id === this.gameId);
				const miniatura = game.miniaturas.find((miniatura: any) => miniatura.titulo.includes('decimo'));
				this.image = "http://www.loteriaelnegrito.com/uploads/" + miniatura.path + miniatura.nombre;
			} else {
				const game = res.listadoSorteos.find((game: any) => game.fecha === this.date);
				const miniatura = game.miniaturas.find((miniatura: any) => miniatura.titulo.includes('decimo'));
				this.image = "http://www.loteriaelnegrito.com/uploads/" + miniatura.path + miniatura.nombre;
			}
		})
		this.searchApiCall(1);


		this.betService.observableBets.subscribe((observableBets: any[]) => {
			// Reset all cantidad_compra in searchResults to 0 using map
			console.log(this.bets);
			this.searchResults = this.searchResults.map(result => {
				result.cantidad_compra = 0;
				return result;
			});
		
			// Update cantidad_compra for matching items by counting occurrences in observableBets
			this.searchResults = this.searchResults.map(result => {
				const count = observableBets.filter(bet => bet.numero === result.numero).length;
				result.cantidad_compra = count;
				return result;
			});
		
			this.bets = observableBets;

			console.log(this.searchResults);
			console.log(observableBets)
		});
	}

	ngAfterContentChecked(): void {
		if (this.load == true && this.ejecute && this.searchNumbers.length > 0) {
			this.ejecute = false
			this.loadNumber();
		}

	}

	cleanFilter(filter: string = 'all'){
		switch (filter) {
			case 'all':
				this.amountFilter = 1;
				this.isCapicua = false;
				this.resetWheels();
				break;
			case 'search':
				this.resetWheels();
				break;
			case 'amount':
				this.amountFilter = 1;
				break;
			case 'capicua':
				this.isCapicua = false;
				break;
		}
	}

	// START WHEELS METHODS

	resetWheels() {
		this.selectedNumbers = new Array(this.displayedWheels.length).fill('*');
		this.combinedNumbers = '*****';
		this.displayedWheels = [[...this.x], [...this.x], [...this.x], [...this.x], [...this.x]]
		this.initialState = true;
		this.newSearchNumber();
	}

	selectOption(option: string) {
		if (this.selectedOption == option)
			this.selectedOption = '';
		else
			this.selectedOption = option;
	}

	initializeWheels(): any[][] {
		const middleIndex = Math.floor(this.x.length / 2);
		return Array(5).fill(null).map(() => {
			const wheel = [...this.x];
			// Rotate the wheel so that '*' is in the middle
			while (wheel[Math.floor(wheel.length / 2)] !== '*') {
				const first = wheel.shift();
				wheel.push(first);
			}
			return wheel;
		});
	}

	updateWheel(wheelIndex: number, newValues: number[]) {
		if (wheelIndex >= 0 && wheelIndex < this.displayedWheels.length) {
			this.displayedWheels[wheelIndex] = newValues;
			this.selectedNumbers[wheelIndex] = newValues[Math.floor(newValues.length / 2)]; // Update selected number
		}
	}

	moveUp(wheelIndex: number) {
		this.initialState = false;
		if (wheelIndex >= 0 && wheelIndex < this.displayedWheels.length) {
			this.animationClasses[wheelIndex] = 'move-up';
			setTimeout(() => {
				const first = this.displayedWheels[wheelIndex].shift();
				if (first !== undefined) {
					this.displayedWheels[wheelIndex].push(first);
					this.selectedNumbers[wheelIndex] = this.displayedWheels[wheelIndex][Math.floor(this.displayedWheels[wheelIndex].length / 2)]; // Update selected number
				}
				this.animationClasses[wheelIndex] = '';
				this.combinedNumbers = this.selectedNumbers.join('');
				this.newSearchNumber();
			}, 300); // Duration of the animation
		}
	}

	getClasses(index: number) {
		return {
			[this.animationClasses[index]]: true,
			'hidden': this.initialState,
			'visible': !this.initialState
		};
	}

	moveDown(wheelIndex: number) {
		this.initialState = false;
		if (wheelIndex >= 0 && wheelIndex < this.displayedWheels.length) {
			this.animationClasses[wheelIndex] = 'move-down';
			setTimeout(() => {
				const last = this.displayedWheels[wheelIndex].pop();
				if (last !== undefined) {
					this.displayedWheels[wheelIndex].unshift(last);
					this.selectedNumbers[wheelIndex] = this.displayedWheels[wheelIndex][Math.floor(this.displayedWheels[wheelIndex].length / 2)]; // Update selected number
				}
				this.animationClasses[wheelIndex] = '';
				console.log(this.selectedNumbers);
				this.combinedNumbers = this.selectedNumbers.join('');
				this.newSearchNumber();
			}, 300); // Duration of the animation
		}
	}
	// FINISH WHEELS METHODS


	// AMOUNT FILTER METHODS
	changeAmount(amount: number) {
		this.amountFilter += amount;
	}

	startChangingAmount(amount: number) {

		this.changeAmount(amount);
		clearInterval(this.intervalId);
		this.intervalId = setInterval(() => {
			if (this.amountFilter <= 1) {
				this.amountFilter = 1;
				clearInterval(this.intervalId);
				this.stopChangingAmount();
				return;
			} else {
				this.changeAmount(amount);
			}
		}, 150);
	}

	stopChangingAmount() {
		this.newSearchNumber();
		clearInterval(this.intervalId);
	}
	// END AMOUNT FILTER METHODS

	onCapicuaCheck() {
		this.newSearchNumber();
	}

	public newSearchNumber() {
		if (this.loading) return;
		this.loading = true;

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.searchApiCall(1);
		}, 700);
	}

	private searchApiCall(page: number = 1) {
		let number = this.combinedNumbers.replace(/\*/g, '_');
		console.log(this.date, this.gameId, number, this.amountFilter, this.isCapicua, page);

		this.gamesApiService.newSearchLottery(this.date, this.gameId, number, this.amountFilter, this.isCapicua, page).pipe(take(1)).subscribe({
			next: (response: any) => {
				console.log(response);
				const mappedData = response.data.map((item: any, i: number) => {
					let cantidad_compra = 0;
					const selectedNumber = this.bets.find((selected: any) => selected.numero === item.numero);
					if (selectedNumber) {
						cantidad_compra = selectedNumber.cantidad_compra;
						item.cantidad -= cantidad_compra;
					}

					return {
						cantidad_compra: cantidad_compra,
						numero: item.numero,
						cantidad: item.cantidad,
						posicion: i,
						sorteo: item.sorteo_numero
					};
				});

				if (page === 1) {
					this.searchResults = []
					this.page = 1;
					this.searchResults = [...mappedData];
					this.noMoreResults = false;
				} else {
					this.searchResults = [...this.searchResults, ...mappedData];
					console.log(this.searchResults);
				}
				this.loading = false;
				console.log(response.next_page_url)
				if (response.next_page_url === null) {
					this.noMoreResults = true;
				}
				console.log(this.searchResults);
			},
			error: (e) => {
				console.error(e);
				this.loading = false;
			},
			complete: () => console.info('complete')
		});
	}
	public onScroll(index: number) {
		console.log(index);
		if (this.noMoreResults || this.loading || this.isFetching) return;

		clearTimeout(this.debounceTimeout);
		this.debounceTimeout = setTimeout(() => {
			const renderedRange = this.viewport.getRenderedRange();
			const totalItems = this.viewport.getDataLength();
			console.log('renderedRange', renderedRange);
			console.log('totalitems', totalItems);

			if (renderedRange.end === totalItems) {
				this.page++;
				this.searchApiCall(this.page);
				console.log('scrolled');
			}
		}, 200); // Adjust the debounce delay as needed
	}

	// OLD SHIT
	loadGroup() {
		this.grupo_id = this.activeRouter.snapshot.queryParams.grupo_id
		this.nombreGrupo = this.activeRouter.snapshot.queryParams.nombre
	}

	loadNumber() {
		let param = this.activeRouter.snapshot.queryParams.numero
		if (param != undefined) {
			this.numberForm.controls.num.setValue(param)
			this.newSearchNumber()
		}
	}

	public getUrlTicket(listados: any) {
		//sacar la url del dia indicado
		//pongo de ejemplo el dia 1

		if (listados[0].miniaturas[1])
			this.url = "http://www.loteriaelnegrito.com/uploads/" + listados[0].miniaturas[1].path + listados[0].miniaturas[1].nombre;
		else
			this.url = "/assets/images/icons/defaultTicket.svg";
	}

	ngAfterViewInit(): void {
	}

	public restarDecimo(l: any): void {
		if (l.cantidad_compra > 0) {
			l.cantidad++;
			l.cantidad_compra--;
			let index = this.bets.indexOf(l);
			this.bets.splice(index, 1);
			this.betService.setBets(this.bets);
			this.coste -= this.precio;
		}
		/*else
		{
		  let index = this.bets.indexOf(l);
		  this.bets.splice(index,1);
		  this.betService.setBets(this.bets);
		}*/
		if (this.betService.bets.length > 1)
			this.betService.setMultiPlayBetLottery(true)
		else
			this.betService.setMultiPlayBetLottery(false)

		this.betService.getSendMode().subscribe(res => {
			if (res)
				this.betService.setCost(this.coste + Number(res.coste));
		})
	}


	public sumarDecimo(l: any): void {
		if (l.cantidad_compra < l.cantidad) {
			l.cantidad--;
			l.cantidad_compra++;

			this.bets.push(l);

			this.betService.setBets(this.bets);

			if (this.betService.bets.length > 1)
				this.betService.setMultiPlayBetLottery(true)
			else
				this.betService.setMultiPlayBetLottery(false)

			this.coste += this.precio;

			this.betService.getSendMode().subscribe(res => {
				if (res)
					this.betService.setCost(this.coste + Number(res.coste));
			})
		}
	}


	selectRandomNumber() {
		// Reset cantidad_compra for all items in searchResults
		this.searchResults.forEach(number => number.cantidad_compra = 0);
		this.bets = [];

		const availableNumbers = this.searchResults.filter(number => number.cantidad_compra === 0);
		if (availableNumbers.length > 0) {
			const randomIndex = Math.floor(Math.random() * availableNumbers.length);
			const selectedNumber = availableNumbers[randomIndex];
			const index = this.searchResults.indexOf(selectedNumber);

			// Scroll to the index with smooth animation
			this.viewport.scrollToIndex(index, 'smooth');

			this.sumarDecimo(selectedNumber);
		}
	}


	openDialog(num: number, draw: number) {
		let aux = {
			num: num,
			draw: draw
		}
		this.dialog.open(AddAlertComponent, { data: aux })
		this.dialogService.setStatusDialog(true);
		this.dialogService.observableLoading.subscribe(res => {
			if (!res) {
				this.dialog.closeAll();
			}
		})
	}

	ngOnDestroy() {
		if (this.betService.sendMode && this.betService.sendMode.id == 1)
			this.betService.clearData();
	}

}
