import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { GroupService } from '@api/services/group.service';
import { HeaderService } from '@api/services/header.service';
import { UserApiService } from '@api/services/user.service';
import { CustomVirtualScroll } from '@classes/CustomVritualScoll';
import { MovimientoDetails } from '@models/MovimientoDetails.model';
import { MClient } from '@models/client.model';
import { Mygroup } from '@models/group.model';
import { MPerson } from '@models/person.model';
import { BottomSheetMovimientos } from '@shared/components/bottomSheet/bottomSheetMovimiento/bottomSheetMovimiento.component';


@Component({
	selector: 'app-single-movement',
	templateUrl: './single-movement.component.html',
	styleUrls: [
		'./single-movement.component.scss',
	],
	providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScroll }]
})
export class SingleMovementComponent implements OnInit, OnChanges {

	@Input() movimientos: any[] = [];
	@Input() cliente?: MClient = null;
	@Input() grupo?: Mygroup = null;

	public title: Array<String> = [];

	public modeGroup: boolean = false;

	public saldo: any;
	public saldoMiembro: any;

	public person: MPerson = null;

	public imagen;
	public cliente_id;
	public nombreUser;
	public firstLetterName;

	constructor(private router: Router, 
				private bottomShet: MatBottomSheet, 
				private headerService: HeaderService,
				private groupService: GroupService,
				private userService: UserApiService) {
	}

	ngOnInit(): void {
		this.headerService.modeGrupoCoolOb.subscribe(res => { 
			this.modeGroup = res;
			if (this.modeGroup) {
				if (this.grupo == null) {
					this.groupService.getGroup.subscribe(res => {
						this.grupo = res;
					});
				}
				if (this.router.url.includes('saldo-persona')) {
					if (this.cliente == null) {
						this.userService.usuarioObservable.subscribe(res => {
							if(res) {
								this.cliente = res.client;
							}
						});
					}
					let member = this.grupo.miembros.filter((x:any) => x.cliente_id == this.cliente.id ? x : '')[0];
					this.saldo = Number(member.saldo.saldo_tarjeta) + Number(member.saldo.saldo_premios) + Number(member.saldo.saldo_no_disponible);
				}
				else {
					if (this.grupo == null) {
						this.groupService.getGroup.subscribe(res => {
							this.grupo = res;
						});
					}
					this.userService.usuarioObservable.subscribe(res => {
						this.person = res;
						this.imagen = res.imagen;
						this.cliente_id = res.client.id;
						this.nombreUser = res.name + " " + res.surname;
						this.firstLetterName = res.name[0].toUpperCase();

						let member = this.grupo.miembros.filter((x:any) => x.cliente_id == this.cliente_id ? x : '')[0];
						this.saldoMiembro = Number(member.saldo.saldo_tarjeta) + Number(member.saldo.saldo_premios) + Number(member.saldo.saldo_no_disponible);
					})
					this.saldo = Number(this.grupo.saldo.saldo_tarjeta)+Number(this.grupo.saldo.saldo_premios) + Number(this.grupo.saldo.saldo_no_disponible);
				}
			}
			else {
				if (this.cliente == null) {
					this.userService.usuarioObservable.subscribe(res => {
						if(res) {
							this.cliente = res.client;
							this.saldo = this.cliente.getSaldoTotal();
						}
					});
				}
				else {
					this.saldo = this.cliente.getSaldoTotal();
				}
			}
		});
	}

	ngOnChanges(): void {
		if (this.movimientos != undefined) {
			for (let movimiento of this.movimientos) {
				switch (movimiento.tipo_movimiento) {
					case 52:
						this.title.push('Conversión');
						break;
					case 74:
						this.title.push('Conversión');
						break;
					case 16:
						this.title.push('Transferencia');
						break;
					default:
						this.title.push(!this.comprobarCobro(movimiento.importe) ? (movimiento.tipo_movimiento == 2 ? 'Premio' : 'Ingreso') : 'Cobro');
						break;
				}
			}
		}
	}

	comprobarCobro(saldo: number) {
		if (saldo >= 0)
			return false;
		return true;
	}

	ingresarBtn() {
		if (this.modeGroup && this.cliente != null) {
			this.router.navigate(['/','cliente','mis-grupos','detalles',this.grupo.cliente_id,this.grupo.nombre,'ingresar']);
		}
		else {
			this.router.navigate(['/', 'cliente', 'ingresar']);
		}
	}

	retirarBtn() {
		if (this.modeGroup && this.cliente != null) {
			this.router.navigate(['/','cliente','mis-grupos','detalles',this.grupo.cliente_id,this.grupo.nombre,'cobrar']);
		}
		else {
			this.router.navigate(['/', 'cliente', 'cobrar']);
		}
	}

	verSaldo()
    {
        this.headerService.set_rutaBack(this.router.url)
        this.router.navigate(['/','cliente','mis-grupos','detalles',this.grupo.cliente_id,this.grupo.nombre,'saldo-persona',this.cliente_id]);
    }

	openSheet(movimiento: any) {
		let movimientoDetails = new MovimientoDetails()
		movimientoDetails.concepto = movimiento.concepto_api
		movimientoDetails.importe = movimiento.importe

		movimientoDetails.fecha = movimiento.fecha

		movimientoDetails.tipo_movimiento = movimiento.importe > 0 ? 'Ingreso Realizado' : 'Cobro efectuado';

		switch (movimiento.tipo_movimiento) {
			case 2:
				movimientoDetails.tipo_movimiento = 'Premio recibido';
				break;
			case 6:
				movimientoDetails.tipo_movimiento = 'Premio recibido';
				break;
			case 52:
				movimientoDetails.tipo_movimiento = 'Conversión';
				break;
			case 74:
				movimientoDetails.tipo_movimiento = 'Conversión';
				break;
			case 16:
				movimientoDetails.tipo_movimiento = 'Transferencia efectuada';
				break;
			default:
				this.title.push(!this.comprobarCobro(movimiento.importe) ? (movimiento.tipo_movimiento == 2 ? 'Premio' : 'Ingreso') : 'Cobro');
				break;
		}

		this.bottomShet.open(BottomSheetMovimientos, { data: { movimiento: movimientoDetails } })
	}

}
