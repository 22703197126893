import { Component, Inject, OnInit } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { GamesApiservice } from "@api/services/games/games-api.service";
import { PedidosService } from "@api/services/pedidos.service";
import { ShipmentFormService } from "@api/services/shipment-form.service";
import { formsEnvioModel } from "@models/api-models/fromsEnvio.model";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'app-bottom-sheet-change-shipment-form',
    styleUrls: ['./bottomSheetChangeShipmentForm.scss'],
    templateUrl: './bottomSheetChangeShipmentForm.html'
})
export class BottomSheetChangeShipmentForm implements OnInit
{
    private order: any
    public formEnvio: formsEnvioModel
    public FormasEnvio: formsEnvioModel[]
    public isLoading: boolean = false

    constructor(
        private bottomSheetRef: MatBottomSheetRef<BottomSheetChangeShipmentForm>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: {order: any},
        private pedidosService: PedidosService,
        private gameApiService: GamesApiservice,
        private shipmentFormService: ShipmentFormService,
        private toatr: ToastrService
    ) {
        this.order = data.order
    }

    ngOnInit(): void {
        this.loadShipmentForms()
    }

    loadShipmentForms()
    {
        this.gameApiService.getFormasEnvio().subscribe(res => {
            //this.FormasEnvio = res // Para todos los juegos
            this.FormasEnvio = res.filter( x => x.id == 5)
            this.formEnvio = this.FormasEnvio.filter( x => x.id == 5)[0];
        })
    }

    isActiveFormaEnvio(formaEnvio: formsEnvioModel): boolean
    {
      if(this.formEnvio)
        return this.formEnvio.id == formaEnvio.id
      else
      {
        if(formaEnvio.id == 1)
          return true
      }
      return false
    }

    checkFormaEnvio(formaEnvio: string)
    {
        this.formEnvio = JSON.parse(formaEnvio)
    }

    updateShipmentForm()
    {
        this.isLoading = true

        this.shipmentFormService.updateShipmentForm(this.order.id,this.formEnvio.id).subscribe((res: any) => {
            if(res.status == 200)
            {
                this.shipmentFormService.setPedido(res.data)
                this.bottomSheetRef.dismiss()
                this.toatr.success("forma de envio cambiada con exito")
            }
            else
            {
                this.toatr.error(res.u_message)
            }

            this.isLoading = false
        })
    }
}