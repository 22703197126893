import { AfterViewInit, Component, ElementRef, Input, Output, Renderer2, ViewChild } from "@angular/core";
import { ColorGenerator } from "@classes/ColorGenerator";

@Component({
    selector: 'app-user-img',
    templateUrl: './user-img.component.html',
    styleUrls: ['user-img.component.scss']
})
export class UserImgComponent implements AfterViewInit
{
    @Input() texto: string
    @Input() colorTexto: string

    @ViewChild("img") img: ElementRef

    colorGenerator: ColorGenerator

    constructor(
        private renderer: Renderer2
    ){
        this.colorGenerator = new ColorGenerator()
    }

    ngAfterViewInit(): void {
        this.loadColor()
    }

    private loadColor()
    {
      let color = this.colorGenerator.strToColor(this.colorTexto)
      this.renderer.setStyle(this.img.nativeElement,"background",color)
    }
}