import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from "@api/services/alert.service";
import { DialogService } from "@api/services/dialog.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'app-add-alert',
    templateUrl: './add-alert.component.html',
    styleUrls: [
        './add-alert.component.scss',
    ]
})

export class AddAlertComponent implements OnInit{

    constructor(
        private alertService: AlertService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogService: DialogService,
        private toastrService: ToastrService, 
    ){}

    ngOnInit(){

    }

    /**
     * Create a new alert.
     * @returns void 
     */
     public addAlert(){

        this.alertService.addAlert(this.data.num, this.data.draw)
        .subscribe(res => { 
            this.dialogService.setStatusDialog(false);
            this.toastrService.info('Alerta creada.');
        }, err => {
            if(err){
                this.toastrService.error('Ya has creado una aviso para este número.');
            }
        });
        
    }

    closeDialog(){
        this.dialogService.setStatusDialog(false);
    }
}