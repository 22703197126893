import { Component, Input, OnInit } from '@angular/core';
import { GamesApiservice } from '@api/services/games/games-api.service';
import { EGamesId } from '@enums/common/games.enum';
import * as moment from 'moment';
import { BetService } from '@modules/games/bet.service';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { CustomVirtualScroll } from '@classes/CustomVritualScoll';

/**
 * Component header, contains a list of days that have a draw in them
 */
@Component({
	selector: 'app-primary-header-lottery',
	templateUrl: './games-primary-header-lottery.component.html',
	styleUrls: [
		'./games-primary-header-lottery.component.scss',
	],
	providers: [
		{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScroll }
	]
})
export class LotteryPrimaryHeaderComponent implements OnInit {

	public games: Array<{ date: string, dayName: string, day: number, month: string, selected: boolean, precio: Number | string }>;
	public addedGames: Array<{}>;
	public firstDay: boolean;

	@Input() drawDates: any;
	@Input() currentGame: any;

	constructor(
		private gamesApiService: GamesApiservice,
		private betService: BetService,
	) {
		this.games = [];
		this.addedGames = [];

	}


	ngOnInit(): void {

		this.loadGames();

	}

	/**
	 * Load the games that are going to be played in the next weeks.
	 */
	loadGames(): void {
		this.firstDay = false;
		const days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
		const months = ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'];

		this.gamesApiService.getNextDates(this.currentGame)
		this.gamesApiService.sorteosAvanzadoObservable$.subscribe(response => {
				const d = new Date();
				console.log(response)
				if (this.currentGame == 6 || this.currentGame == 8) {
					
					response.listadoEspecial.forEach((game: { fecha: string | number | Date, juego_id: string | number | null, precio: number | string, hora_sorteo: string }) => {

						if (game.juego_id != this.currentGame)
							return

						this.betService.setGuindaPrice

						var selected = false;
						if (!this.firstDay) {
							selected = true;
							this.firstDay = true;
							//this.betService.setDate(moment(new Date(game.fecha + ' ' + game.hora_sorteo)).format('YYYY-MM-DD'));
						}

						const date = new Date(game.fecha + ' ' + game.hora_sorteo);
						const dayName = days[date.getDay()];
						const day = date.getUTCDate();
						const month = months[date.getMonth()];
						const dateString = moment(date).format('YYYY-MM-DD');
						const precio = game.precio;
						this.games.push({ date: dateString, dayName, day, month, selected, precio });

					});
					this.betService.setGuindaPrice(response.listadoEspecial[0].juego.precio_guinda);
				}
				else {
					response.listadoSorteos.forEach((game: { fecha: string | number | Date, precio: string | number, hora_sorteo: string }) => {
						var selected = false;
						if (!this.firstDay) {
							selected = true;
							this.firstDay = true;
							//this.betService.setDate(moment(new Date(game.fecha)).format('YYYY-MM-DD'));
						}

						const date = new Date(game.fecha);

						const dayName = days[date.getDay()];
						const day = date.getUTCDate();
						const month = months[date.getMonth()];
						const dateString = moment(date).format('YYYY-MM-DD');

						const precio = game.precio
						this.games.push({ date: dateString, dayName, day, month, selected, precio });
					});
					this.betService.setGuindaPrice(response.listadoSorteos[0].juego.precio_guinda);
				}



			});

	}

	/**
	 * Delete a game from de added games array that represents the days that the player will bet
	 * @param date The day of the game
	 */
	public dateSelected(date: { date: string, precio: number }): void {

		const index: number = this.addedGames.indexOf(date);
		for (var i = 0; i < this.games.length; i++) {
			if (this.games[i].date == date.date) {
				this.games[i].selected = true;
			}
			else {
				this.games[i].selected = false;
			}
		}
		if (this.addedGames.length > 0)
			this.addedGames.splice(index, 1);
		this.addedGames.push(date.date);
		this.betService.setAddedDays(this.addedGames);
		this.betService.setDate(date.date);
		this.betService.lotterySimpleTicketCost = date.precio
		this.betService.calculateCost()
	}

	scroll(positionDaySelected) {
		/*this.perfectScroll.directiveRef.update();
		this.perfectScroll.directiveRef.scrollToLeft(positionDaySelected);*/
	}
}

