import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRouterService } from "@shared/services/api-router.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
    providedIn: 'root'
})
export class AlertService extends ApiRouterService {
    
    constructor(
        private http: HttpClient,
        private authService: AuthenticationService
    ){
        super();
    }

    /**
     * Get list of alerts.
     * @returns Callback from the API.
     */
    public getAlerts(){

        const token = this.authService.loadToken();
        const tokenJWT = this.authService.decodeToken(token);
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        const httpOptions = { headers: this.httpHeaders };
        
        return this.http.get(`${this.URL}personas/${tokenJWT.idClient}/alertasLoteria`, httpOptions);

    }

    /**
     * Get a single alert.
     * @param num 
     * @param draw 
     * @returns Callback from the API.
     */
    public getAlert(id: number){

        const token = this.authService.loadToken();
        const tokenJWT = this.authService.decodeToken(token);
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        const httpOptions = { headers: this.httpHeaders };

        return this.http.get(`${this.URL}personas/${tokenJWT.idClient}/alertasLoteriaInformacionSorteo?id=${id}`, httpOptions);

    }

    /**
     * Create a new alert.
     * @param num 
     * @param draw 
     * @returns POST request to API alert.
     */
    public addAlert(num: number, draw: number){

        const token = this.authService.loadToken();
        const tokenJWT = this.authService.decodeToken(token);
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        const httpOptions = { headers: this.httpHeaders };

        return this.http.post(`${this.URL}personas/${tokenJWT.idClient}/alertasLoteria?numero=${num}&sorteo=${draw}`, null, httpOptions);

    }

    /**
     * Delete single alert.
     * @param num 
     * @param draw 
     * @returns DELETE request to API alert.
     */
    public deleteAlert(num: number, draw: number){

        const token = this.authService.loadToken();
        const tokenJWT = this.authService.decodeToken(token);
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        const httpOptions = { headers: this.httpHeaders };

        return this.http.delete(`${this.URL}personas/${tokenJWT.idClient}/alertasLoteria?numero=${num}&sorteo=${draw}`, httpOptions);

    }

    /**
     * Change status of a single alert.
     * @param num 
     * @param draw 
     * @param status 
     * @returns POST request to API alert.
     */
    public changeStatusAlert(num: number, draw: number, status: number){

        const token = this.authService.loadToken();
        const tokenJWT = this.authService.decodeToken(token);
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            token
        });
        const httpOptions = { headers: this.httpHeaders };

        return this.http.post(`${this.URL}personas/${tokenJWT.idClient}/alertasLoteria?numero=${num}&sorteo=${draw}&status=${status}`, null, httpOptions);

    }

}