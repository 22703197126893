<div class="spinner-wrapper" *ngIf="loading">
	<app-spinner forceTo='component-sized'></app-spinner>
</div>
<div class="content-list" [ngClass]="this.device == devices.DESKTOP ? 'content-desktop' : ''">
	<div class="plays-list">
		<div style="height: 100%; overflow: scroll;" infiniteScroll [infiniteScrollDistance]="2"
			[infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="200" [scrollWindow]="false"
			(scrolled)="onScroll()">
			<ng-container *ngIf="finalPlays?.length > 0">
				<div class="plays-row" *ngFor="let play of finalPlays; let i = index; let last = last">
					<app-participation-product [play]="play" [device]="this.device" [abono]="abonos" (dropAbono)="dropAbonoList($event)"
					[ngClass]="{'mb5': last}"></app-participation-product>
				</div>
			</ng-container>
			<div class="no-plays" *ngIf="finalPlays?.length == 0 && loading == false">
				<h3 class="no-plays-text">
					Aquí parece que todavía no hay mucho que ver
				</h3>
				<img class="image" src="/assets/images/icons/plays-list/no-plays.svg" />
			</div>
			<div style="display: flex; justify-content: center; margin: 25px;" *ngIf="spinnerBottomLoadVisibility">
				<mat-progress-spinner class="spinner" [mode]="'indeterminate'"
					[diameter]="35"> </mat-progress-spinner>
			</div>
		</div>
	</div>
</div>