// tslint:disable: max-line-length
// tslint:disable: triple-equals

import { AfterContentChecked, Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild, NgZone, AfterViewInit} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BetService } from '@modules/games/bet.service';
import { IToast } from 'src/app/interfaces/toast.interface';

const changeToSimpleBet = '¡Atención! Cambio a apuesta simple';
const changeToMultiple44 = 'Apuesta múltiple de 44.';
const changeToMultipleBet = '¡Atención! Cambio a apuesta múltiple';
const limitMaxBet = 'Ha llegado al límite de apuesta.';

import { GamesApiservice } from '@api/services/games/games-api.service';
import { EGamesId } from '@enums/common/games.enum';
import { ManualPlayLotteryComponent } from '../manual-play-lottery.component';
import { AddAlertComponent } from '@modules/games/components/alert/add-alert/add-alert.component';
import { DialogService } from '@api/services/dialog.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { formsEnvioModel } from '@models/api-models/fromsEnvio.model';
import { take, debounceTime, filter, map, pairwise, throttleTime } from 'rxjs/operators';
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { CustomVirtualScroll } from '@classes/CustomVritualScoll';
import * as moment from 'moment';

@Component({
	selector: 'app-manual-play-lottery-mobile',
	templateUrl: './manual-play-lottery-mobile.component.html',
	styleUrls: ['./manual-play-lottery-mobile.component.scss'],
	providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScroll }]
})
export class ManualPlayLotteryMobileComponent extends ManualPlayLotteryComponent implements AfterViewInit, OnInit, OnDestroy, AfterContentChecked  {

	public toast: IToast;
	public gameNumbers: any;
	public showNumbers: any;
	public url: string = 'http://www.loteriaelnegrito.com/uploads/imagenes/loterianavidad/emails/202307/emails-102mail.jpg';
	public date: string;
	private drawDate: string;
	public bets: any[];
	public numBuscado: string;
	public searchNumbers: any;
	public load: Boolean;
	public precio: number;
	private coste: number = 0;
	private grupo_id = 0;
	private nombreGrupo = "";
	private ejecute = true;
	private timeout = null;
	public spinnerBottomLoadVisibility = false;

	private page: number = 1
	public lastPage = false

	public FormasEnvio: formsEnvioModel[];

	@Input() gameId: EGamesId;
	@ViewChild("search") searchBar: ElementRef;
	@ViewChild(CdkVirtualScrollViewport) scroller: CdkVirtualScrollViewport;

	numberForm = new FormGroup({
		num: new FormControl('')
	});

	constructor(

		public dialog: MatDialog,
		private betService: BetService,
		private gamesApiService: GamesApiservice,
		private dialogService: DialogService,
		private router: Router,
		private ngZone: NgZone,
		private activeRouter: ActivatedRoute,
		@Inject(MAT_DIALOG_DATA) public data: any

	) {

		super();

	}

	ngOnInit() {

		this.options = this.betService.options;
		this.showNumbers = [];
		this.gameNumbers = [];
		this.numBuscado = "";
		this.searchNumbers = [];
		this.date = "";
		this.bets = [];
		this.load = false;
		this.FormasEnvio = [];
		this.date = this.betService.date;
		this.drawDate = this.date
		this.loadGroup()

		if (this.activeRouter.snapshot.queryParams.date && this.activeRouter.snapshot.queryParams.numero)
			this.date = this.activeRouter.snapshot.queryParams.date

		this.gamesApiService.getNumbersLotteryDay(this.gameId, this.date)
		this.gamesApiService.numbersLotteryDayObservable$
			.pipe(take(1))
			.subscribe(
				res => {
					this.gameNumbers = res.data;
					this.loadLottery();
				},
				err => {
					console.log(err)
				}
			)


		this.gamesApiService.getLottery(this.date, this.gameId)
			.pipe(take(1))
			.subscribe(
				res => {
					if (this.gameId == 6 || this.gameId == 8) {

						let subArray: any[] = []

						res.listadoEspecial.forEach(x => {
							if (x.juego_id == this.gameId)
								subArray.push(x)
						});

						this.precio = Number(subArray[0].precio);
						this.getUrlTicket(subArray)
						this.betService.setGuindaPrice(subArray[0].juego.precio_guinda);
					}
					else {
						this.precio = Number(res.listadoSorteos[0].precio);
						this.getUrlTicket(res.listadoSorteos)
						this.betService.setGuindaPrice(res.listadoSorteos[0].juego.precio_guinda);
					}

				},
				err => {
					console.log(err)
				}
			)
	}

	ngAfterContentChecked(): void {
		if (this.load == true && this.ejecute && this.searchNumbers.length > 0) {
			this.ejecute = false
			this.loadNumber();
		}

	}

	loadGroup() {
		this.grupo_id = this.activeRouter.snapshot.queryParams.grupo_id
		this.nombreGrupo = this.activeRouter.snapshot.queryParams.nombre
	}

	loadNumber() {
		let param = this.activeRouter.snapshot.queryParams.numero
		if (param != undefined) {
			this.numberForm.controls.num.setValue(param)
			this.newSearchNumber()
		}
	}

	public getUrlTicket(listados: any) {
		//sacar la url del dia indicado
		//pongo de ejemplo el dia 1

		if (listados[0].miniaturas[1])
			this.url = "http://www.loteriaelnegrito.com/uploads/" + listados[0].miniaturas[1].path + listados[0].miniaturas[1].nombre;
		else
			this.url = "/assets/images/icons/defaultTicket.svg";
	}

	private loadLottery(): void {

		if (this.showNumbers == undefined)
			this.showNumbers = [];
		for (let i = 0; i < this.gameNumbers.length; i++) {
			let bet = {
				cantidad_compra: 0,
				numero: this.gameNumbers[i].numero,
				cantidad: this.gameNumbers[i].cantidad,
				posicion: i,
				sorteo: this.gameNumbers[i].sorteo_numero
			}
			if (this.gameNumbers[i].cantidad > 0) {
				this.showNumbers.push(bet);
				this.searchNumbers.push(bet);
			}
		}

		this.load = true;
	}

	ngAfterViewInit(): void {
		this.onScroll();
	}

	public onScroll() {
			this.scroller.elementScrolled().pipe(
				map(() => this.scroller.measureScrollOffset('bottom')),
				pairwise(),
				filter(([y1, y2]) => (y2 < y1 && y2 > 100)),
				throttleTime(1500),
			).subscribe(() => {
				this.ngZone.run(() => {
					if (!this.lastPage) {
						this.page++;

						if (this.page > 1)
							this.spinnerBottomLoadVisibility = true;

						this.gamesApiService.getNumbersLotteryDay(this.gameId, this.date, this.numBuscado, this.page)
						this.gamesApiService.numbersLotteryDayObservable$
							.pipe(take(1))
							.subscribe(
								res => {
									console.log(res)
									this.gameNumbers = res.data;
									this.loadLottery();
								},
								err => {
									console.log(err)
								}
							)
					}
				})
			})
	}

	//process the number of the search
	public newSearchNumber() {

		clearTimeout(this.timeout);

		this.numBuscado = "";
		this.searchNumbers = [];

		this.timeout = setTimeout(() => {

			this.numBuscado += this.numberForm.controls.num.value;

			this.load = false;

			this.gamesApiService.getNumbersLotteryDay(this.gameId, this.date, this.numBuscado)
			this.gamesApiService.numbersLotteryDayObservable$
				.pipe(take(1))
				.subscribe(
					res => {
						console.log(res)
						this.gameNumbers = res.data;
						this.loadLottery();
					},
					err => {
						console.log(err)
					}
				)

			if (this.searchNumbers.length == 0 && this.numBuscado.length == 5) {
				//this.openDialog(Number(this.numBuscado), this.showNumbers[0].sorteo);

				var queryParams = { queryParams: { "num": this.numBuscado, "date": this.drawDate } }

				if (this.grupo_id > 0) {
					queryParams.queryParams['grupo_id'] = this.grupo_id
					queryParams.queryParams['nombre'] = this.nombreGrupo
				}

				//this.router.navigate(['/', 'puntos-venta'], queryParams);
			}
		}, 500);
	}


	public restarDecimo(l: any): void {
		if (l.cantidad_compra > 0) {
			l.cantidad++;
			l.cantidad_compra--;
			let index = this.bets.indexOf(l);
			this.bets.splice(index, 1);
			this.betService.setBets(this.bets);
			this.coste -= this.precio;
		}
		/*else
		{
		  let index = this.bets.indexOf(l);
		  this.bets.splice(index,1);
		  this.betService.setBets(this.bets);
		}*/
		if (this.betService.bets.length > 1)
			this.betService.setMultiPlayBetLottery(true)
		else
			this.betService.setMultiPlayBetLottery(false)

		this.betService.getSendMode().subscribe(res => {
			if (res)
				this.betService.setCost(this.coste + Number(res.coste));
		})
	}


	public sumarDecimo(l: any): void {
		if (l.cantidad_compra < l.cantidad) {
			l.cantidad--;
			l.cantidad_compra++;

			this.bets.push(l);

			this.betService.setBets(this.bets);

			if (this.betService.bets.length > 1)
				this.betService.setMultiPlayBetLottery(true)
			else
				this.betService.setMultiPlayBetLottery(false)

			this.coste += this.precio;

			this.betService.getSendMode().subscribe(res => {
				if (res)
					this.betService.setCost(this.coste + Number(res.coste));
			})
		}
	}

	openDialog(num: number, draw: number) {
		let aux = {
			num: num,
			draw: draw
		}
		this.dialog.open(AddAlertComponent, { data: aux })
		this.dialogService.setStatusDialog(true);
		this.dialogService.observableLoading.subscribe(res => {
			if (!res) {
				this.dialog.closeAll();
			}
		})
	}

	ngOnDestroy() {
		if (this.betService.sendMode && this.betService.sendMode.id == 1)
			this.betService.clearData();
	}

}
