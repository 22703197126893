import { MClient } from './client.model';

export class MPerson {

  constructor(
    public name: string,
    public surname: string,
    public lastname: string,
    public birthDate: Date,
    public email: string,
    public client: MClient | null,
    public cp ?: number,
    public password ?: string,
    public adress ? : string,
    public city ?: string,
    public province ?: string,
    public identification ?: string,
    public countNumber ?: string,
    public phone?: number,
    public numCard ?: string,
    public email_validado?: boolean,
    public telefono_validado?: boolean,
    public iban?: string,
    
  ) {}

}
