import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { GamesApiservice } from "@api/services/games/games-api.service";
import { UserApiService } from "@api/services/user.service";
import { formsEnvioModel } from "@models/api-models/fromsEnvio.model";
import { MPerson } from "@models/person.model";
import { BetService } from "@modules/games/bet.service";

@Component({
	selector: 'app-forma-envio',
	templateUrl: './forma-envio.component.html',
	styleUrls: ['./forma-envio.component.scss']
})
export class formaEnvioComponent implements OnInit {
	public FormasEnvio: formsEnvioModel[];
	public selectedOption: formsEnvioModel;
	public user: MPerson;

	@Output() ayudaClicked = new EventEmitter<{selectedOption: any, action: string}>();

	constructor(private betService: BetService,
		private gamesApiService: GamesApiservice,
		private userService: UserApiService) {
		this.FormasEnvio = [];
	}
	ngOnInit() {
		this.loadFormaEnvio();
	}

	public loadFormaEnvio() {
		this.gamesApiService.getFormasEnvio().subscribe((x: formsEnvioModel[]) => {
			this.FormasEnvio.push(...x);
			const defaultOption = this.FormasEnvio.find(option => option.id === 1);
			if (defaultOption) {
				this.selectedOption = defaultOption;
			}
		});
	}

	isActiveFormaEnvio(formaEnvio: formsEnvioModel): boolean {
		if (this.betService.sendMode != null)
			return this.betService.sendMode.id == formaEnvio.id
		else {
			if (formaEnvio.id == 1)
				return true
		}
		return false
	}

	selectOption(option: formsEnvioModel) {
		this.selectedOption = option;
		let formaEnv: formsEnvioModel = this.selectedOption;
		this.betService.setSendMode(formaEnv);
		this.betService.calculateCost();
		this.ayudaClicked.emit({
			selectedOption: this.selectedOption,
			action: 'none'
		});
	}

	onHelpClick() {
		this.ayudaClicked.emit({
			selectedOption: this.selectedOption,
			action: 'help'
		});
	}
}