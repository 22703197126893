import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HeaderService } from "@api/services/header.service";

@Component({
    selector:'app-modal-share',
    templateUrl:'./modalShare.component.html',
    styleUrls:['./modalShare.component.scss'],
})
export class modalShareComponent
{
    public modeGroup: boolean = false;
    public link: string = '';
    public descripcion = '';
    constructor(@Inject(MAT_DIALOG_DATA) public data: {
        title: string,
        link: string
        descripcion: string},
                headerService: HeaderService
    ){
        this.link = data.link;
        this.descripcion = data.descripcion;
        headerService.modeGrupoCoolOb.subscribe(res=>this.modeGroup=res);
    }
}