import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { AuthenticationService } from '@api/services/authentication.service';
import { azureConfigService } from '@api/services/azureconfig.service';
import { ConfigurationApiService } from '@api/services/configuration.service';
import { FooterService } from '@api/services/footer.service';
import { GroupService } from '@api/services/group.service';
import { HeaderService } from '@api/services/header.service';
import { playFreeService } from '@api/services/playFree.service';
import { UserApiService } from '@api/services/user.service';
import { FireBase } from '@classes/fireBase';
import { logEvent } from '@firebase/analytics';
import { Mygroup } from '@models/group.model';
import { MPerson } from '@models/person.model';
import { BetService } from '@modules/games/bet.service';
import { modalAlertaComponent } from '@shared/components/modals/modalAlerta/modalAlerta.component';
import { modalPinComponent } from '@shared/components/modals/modalPin/modalPin.component';
import { modalYesNoComponent } from '@shared/components/modals/modalYesNo/modalYesNo.component';
import { ToastrService } from 'ngx-toastr';
import { filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * Component that represents the footer of the playbets module, it shows the cost of the bet and the button to play the bets
 */
@Component({
	selector: 'app-games-footer',
	templateUrl: './games-footer.component.html',
	styleUrls: [
		'./games-footer.component.scss',
	]
})
export class GamesFooterComponent extends FireBase implements OnChanges, OnInit, OnDestroy {

	@Output() betEvent = new EventEmitter();
	@Input() cost: number = 0;
	guindaCostConfig: number = 0;
	guindaCost: number = 0;
	@Input() titlebutton: String = "Jugar";
	@Input() manual: Boolean = false
	@Input() donacion: number = null
	@Output() clickEvent: EventEmitter<Boolean> = new EventEmitter()

	public costCents: string;
	public disabled = false;
	public loading = false;
	public saldoCliente: number = 0;
	public grupo_id: number = 0;
	private sendMode: number = 1;
	public nombre_grupo: number;
	public activeClass = "activated-button";
	public saldo: number;
	public cliente_id: number = 0;
	public user: MPerson
	private freeBeet: Boolean = false
	public game_id: number = 0;
	public guinda: Boolean = false;

	constructor(
		protected betService: BetService,
		protected router: Router,
		protected activateRouter: ActivatedRoute,
		protected toastrService: ToastrService,
		protected playFreeService: playFreeService,
		protected dialog: MatDialog,
		protected userService: UserApiService,
		protected headerService: HeaderService,
		protected footerService: FooterService,
		protected grupoService: GroupService,
		protected authenticationService: AuthenticationService,
		protected configService: ConfigurationApiService,
		protected azureService: azureConfigService,
	) {
		super()
		this.cost = betService.cost;

		this.getUserInfo()
		footerService.set_Show_footer(false)

		this.userService.usuarioObservable.subscribe(res => {
			if (res)
				this.saldoCliente = res.client.getSaldoTotal()
		});

		betService.costObservable$.subscribe((newCost: number) => {

			this.cost = newCost;

			if (this.cost.toString().split('.')[1] === undefined) {

				this.costCents = '00';

			} else {

				this.costCents = this.cost.toString().split('.')[1];

			}
		});

	}

	ngOnInit(): void {
		this.modoGrupo();
		this.loadFreeBet();

		if (this.authenticationService.isAuthenticated())
			this.loadSaldoCliente();

		/*this.betService.addedDaysObservable$.subscribe((days) => {

			if (days.length <= 0 ){
				this.disabled = true;
			} else {
				this.disabled = false;
			}


		});*/

		this.betService.observablenumberBets.subscribe(res => {
			if (res > 0 && !this.loading)
				this.disabled = false;
			else
				this.disabled = true;
		})

		this.betService.guindaObservable$.subscribe(res => { this.guinda = res })

		this.betService.guindaPriceObservable$.subscribe(res => { this.guindaCostConfig = res });

		this.betService.optionsObservable$.subscribe((res: any) => {
			if (res.idGame != this.game_id) {
				this.game_id = res.idGame
			}
		});

		this.betService.totalBetsObservable$.subscribe(res => {
			this.guindaCost = res * this.guindaCostConfig;
		})

	}

	loadFreeBet() {
		this.betService.freeBetObservable$.subscribe(res => this.freeBeet = res)
	}

	getUserInfo() {
		this.userService.usuarioObservable.subscribe(res => {
			if (res)
				this.user = res
		})
	}

	modoGrupo() {
		if (this.activateRouter.snapshot.queryParams.grupo_id) {
			this.grupo_id = this.activateRouter.snapshot.queryParams.grupo_id;
			this.nombre_grupo = this.activateRouter.snapshot.queryParams.nombre;
			this.activeClass += " activated-button-grupo";
			this.headerService.set_modeGrupoCool(true);
			this.betService.setGrupoId(this.grupo_id);
		}

	}

	loadSaldoCliente() {
		if (!this.grupo_id) {
			this.userService.usuarioObservable.subscribe(res => {
				if (res) {
					this.saldoCliente = res.client.getSaldoTotal();
					this.cliente_id = res.client.id
				}
			});
		}
		else {
			this.grupoService.get_grupo(this.grupo_id).subscribe((res: Mygroup) => {
				this.saldoCliente = Number(res.saldo.saldo_tarjeta) + Number(res.saldo.saldo_premios) + Number(res.saldo.saldo_no_disponible);
			})
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.cost != undefined) {
			const currentCost: SimpleChange = changes.cost;
			this.cost = currentCost.currentValue;

			this.costCents = '00';
			if (this.cost.toString().split('.')[1] === undefined) {

				this.costCents = '00';

			} else {

				this.costCents = this.cost.toString().split('.')[1];

			}
		}
	}


	saldoInsuficienteBet() {

		this.betService.saveBetData();

		console.log('Saldo insuficiente guardamos los datos de la jugada');

		let texto = "No tienes suficiente saldo para realizar el pedido. Tendrás que cargar."
		let dialogAlert = this.dialog.open(modalYesNoComponent, {
			panelClass: 'modal-custom',
			data: {
				title: 'Upss!! Te falta saldo',
				texto,
				buttonConfirm: 'Cargar',
				buttonCancel: 'Cancelar',
				buttonConfirmColor: "blue",
				showIcon: false,
				showOptionCheck: false
			}
		});

		dialogAlert.afterClosed().subscribe(res => {
			if (res) {
				localStorage.setItem("url", this.router.url)

				if (this.betService.empresa != null) {
					localStorage.setItem("empresa", JSON.stringify({
						"empresa_id": this.betService.empresa.id,
						"bets": this.betService.bets,
						"envio_id": this.betService.sendMode != null ? this.betService.sendMode.id : 1,
						"slug": this.betService.empresa.slug,
						"importe": this.betService.cost
					}));
				}

				this.router.navigate(['/cliente/ingresar']);
			}
		})
	}

	playBet(): void {
		if (!this.manual) {
			if (this.authenticationService.isAuthenticated()) {

				if (this.betService.empresa != null) {
					if (this.betService.empresa.azureadactive) {
						this.userService.azureloginObservable.subscribe(res => {
							let azureLogged = res.isValidUser(this.betService.empresa.id);
							if (!azureLogged) {
								this.azureService.updateAuthConfig(this.betService.empresa.tenantid, this.betService.empresa.slug);
								this.loginAzure();
								return;
							}
						});
					}
					this.betService.checkPlay(this.betService.empresa.id).subscribe((res: any) => {

						if (res.data) {

							let nuevoSaldo = this.saldoCliente - (this.betService.cost + this.guindaCost);

							if (nuevoSaldo < 0 || this.saldoCliente == 0)
								this.saldoInsuficienteBet();
							else {
								if (this.betService.sendMode) {
									if (this.betService.sendMode.id > 1 && this.betService.sendMode.id != 5 && !this.betService.sendMode.sede) {
										this.sendMode = this.betService.sendMode.id;
										this.router.navigate(['/confirmar-envio']);
										return;
									}

									this.OpenDialogCompra()
								}
								else {
									this.OpenDialogCompra()
								}
							}
						} else {
							this.toastrService.error('Tu empresa ha limitado el número de décimos que puede comprar cada persona, y ya has alcanzado el maximo.');
						}

					})
				} else {

					let nuevoSaldo = this.saldoCliente - (this.betService.cost + this.guindaCost);

					if (nuevoSaldo < 0 || this.saldoCliente == 0)
						this.saldoInsuficienteBet();
					else {
						if (this.betService.sendMode) {
							if (this.betService.sendMode.id > 1 && this.betService.sendMode.id != 5 && !this.betService.sendMode.sede) {
								this.sendMode = this.betService.sendMode.id;
								this.router.navigate(['/confirmar-envio']);
								return;
							}

							this.OpenDialogCompra()
						}
						else {
							this.OpenDialogCompra()
						}
					}
				}

			}
			else {
				localStorage.setItem("last-url", this.router.url)

				if (this.betService.empresa != null) {
					localStorage.setItem("empresa", JSON.stringify({
						"empresa_id": this.betService.empresa.id,
						"bets": this.betService.bets,
						"envio_id": this.betService.sendMode != null ? this.betService.sendMode.id : 1,
						"slug": this.betService.empresa.slug,
						"importe": this.betService.cost
					}));
				}

				this.router.navigate(['cliente/menu']);
			}
		}
		else
			this.clickEvent.emit(true)
	}

	loginAzure() {
		let texto = "Su empresa requiere hacer login con azure para poder comprar. Desea continuar?";

		const dialog = this.dialog.open(modalYesNoComponent, {
			panelClass: 'modal-custom',
			data: {
				title: 'Login con Azure',
				texto,
				buttonConfirm: 'Continuar',
				btnConfirmColor: 'blue',
				buttonCancel: 'Cancelar',
				showIcon: false,
				showOptionCheck: false
			}
		})
		localStorage.setItem('azureadlogin', this.betService.empresa.slug);
		dialog.afterClosed().subscribe(res => {
			if (res) {
				this.azureService.login();
			}
			else {
				this.router.navigate(['cliente/loteriaempresas']);
			}
		})
	}

	private OpenDialogCompra() {
		let nuevoSaldo = this.saldoCliente - this.betService.cost;
		//Establecemos los datos del modal / set data to alert modal
		let texto = "Esta jugada tiene un coste de:</br></br>";
		let coste = this.betService.cost;
		if (this.guinda) {
			coste += this.guindaCost
			nuevoSaldo -= this.guindaCost
		}

		texto += "<h2 class='currency'>" + coste + " €</h2></br>";
		texto += "Saldo restante: <strong>" + Math.round(nuevoSaldo * 100) / 100 + " €</strong>";

		if (this.freeBeet) {
			var alertDialog = this.dialog.open(modalAlertaComponent, {
				panelClass: 'modal-custom',
				data: {
					title: 'Confirmar Jugada',
					texto: "Esta jugada no tiene coste ¡¡ES GRATIS!!",
					buttonConfirm: 'Aceptar',
					showOptionCheck: true
				}
			});
		}
		else {
			var alertDialog = this.dialog.open(modalAlertaComponent, {
				panelClass: 'modal-custom',
				data: {
					title: 'Confirmar Jugada',
					texto,
					buttonConfirm: 'Pagar jugada',
					showOptionCheck: true
				}
			});
		}

		alertDialog.afterClosed().subscribe(res => {
			if (res) {
				if (this.freeBeet) {
					this.playFreeService.invitadoJugaGratis.pipe(take(1)).subscribe(res => {
						if (res.movil != this.user.phone)
							this.checkSecurity()
					})
				}
				else
					this.checkSecurity();
			}
		});
	}

	checkSecurity() {
		if (!this.grupo_id) {
			this.userService.getSeguridad().subscribe((res) => {
				if (res.status == 200) {
					if (res.data.compra_producto) {
						let checkPinDialog = this.dialog.open(modalPinComponent, {
							panelClass: 'modal-custom',
							disableClose: true,
							data: {
								'title': "Escribe tu PIN de Seguridad",
								'mode': "Check",
								'parameters': null
							}
						});
						return checkPinDialog.afterClosed().subscribe((res) => {
							if (res == true) {
								this.playBetAux();
							}
						});
					}
					else {
						this.playBetAux();
					}
				}
				else {
					this.playBetAux();
				}
				return false;
			});
		}
		else {
			this.playBetAux();
		}
	}

	playBetAux() {

		let aux = false;
		if (this.betService.options.idGame === 1) {
			this.betService.bets.forEach(element => {
				if (element.numbersChecked.length >= 5 || element.numbersChecked.length == 0)
					aux = true
				else {
					aux = false
					return
				}
			});
		}

		if ((this.betService.options.idGame === 11 && this.betService.simpleBet && this.betService.bets.length < 2)
			|| (this.betService.options.idGame === 1 && !aux)) {
			this.toastrService.error('Debe rellenar una apuesta valida para poder comprar.');
		} else {
			this.loading = true;
			this.betService.setLoading(true);
			this.disabled = true;
			this.betService.playBet()?.subscribe((res) => {

				if (this.betService.freeBet) {
					this.playFreeService.addIntivado(res).subscribe((res3: any) => {
					});
				}

				//Actualizamos el salo del usuario
				let guindaAux = 0
				if (this.guinda)
					guindaAux = this.guindaCost

				this.userService.setSaldo(this.saldoCliente - (this.cost + guindaAux))

				this.betService.getPlayData(res).subscribe((res2: any) => {

					const betId = res2[0].apuestas[0].jugada_id;

					//Obtenemos el pedido id
					let pedido_id = res2[0].pedido_id;

					let paramFireBase = {
						transaction_id: pedido_id,
						currency: 'EUR',
						GAME_ID: this.betService.options.idGame,
						PLAYS_AMOUNT: this.betService.bets.length,
						IS_SUCCESSFUL: true,
						METHOD: "SIMPLE",
						CLIENT_ID: res2[0].cliente_id,
						value: this.betService.cost
					}

					if (this.grupo_id)
						paramFireBase.CLIENT_ID = this.grupo_id

					if (environment.production) {
						if (this.betService.freeBet)
							logEvent(this.analytics, "FREE_PLAY_STARTED", paramFireBase)
						else
							logEvent(this.analytics, "purchase", paramFireBase)
					}

					if (!this.grupo_id) {
						if (this.betService.options.idGame != 6 && this.betService.options.idGame != 7 && this.betService.options.idGame != 8)
							this.router.navigateByUrl('/cliente/jugadas/' + betId);
						else {
							if (this.betService.multiPlayBetLottery)
								this.router.navigateByUrl('/cliente/jugadas')
							else
								this.router.navigateByUrl('/cliente/jugadas/' + betId);
						}
					}
					else {
						this.grupoService.get_grupo(this.grupo_id).subscribe((res: Mygroup) => {
							this.grupoService.set_group(res)

							if (this.betService.options.idGame != 6 && this.betService.options.idGame != 7 && this.betService.options.idGame != 8)
								this.router.navigateByUrl('/cliente/mis-grupos/detalles/' + this.grupo_id + "/" + this.nombre_grupo + "/jugada/" + betId);
							else {
								if (this.betService.multiPlayBetLottery)
									this.router.navigateByUrl('/cliente/mis-grupos/detalles/' + this.grupo_id + "/" + this.nombre_grupo + "/boletos")
								else
									this.router.navigateByUrl('/cliente/mis-grupos/detalles/' + this.grupo_id + "/" + this.nombre_grupo + "/jugada/" + betId);
							}
						})


					}

				});

			}, err => {

				let errorMessage = "Se produjo un error al realizar la compra, Por favor intentelo de nuevo mas tarde"

				if (this.betService.empresa) {
					if (err.error.indexOf("No tiene permitido comprar mas decimos de uno de los numeros") >= 0) //Chapuzero pero hay que mejorar las respuestas de la api
						errorMessage = "Upss!!! Tu empresa ha limitado el número de décimos que puede comprar cada persona, y ya has alcanzado el maximo."
				}

				this.toastrService.error(err.error);
				this.betService.setLoading(false);
				this.loading = false;
				this.disabled = false;

				let paramFireBase = {
					CLIENT_ID: this.cliente_id
				}
				logEvent(this.analytics, "PLAY_INTENDED", paramFireBase)

			});
		}
		//this.betService.setLoading(true);
	}

	ngOnDestroy() {
		this.footerService.set_Show_footer(true)

		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((x: NavigationEnd) => {
			if (this.sendMode != 4) //Reseteamos el betservice en caso de que no sea forma de envio 24 horas
			{
				if (!x.url.includes("grupos"))
					this.headerService.set_modeGrupoCool(false);

				this.betService.clearData();
				this.betService.setGrupoId(0);
				this.betService.setEmpresa(undefined);
				this.betService.clearSendMode();
				this.betService.setFreeBet(false)
				this.playFreeService.setInvitadoJugadaGratis(null)
				this.betService.setLoading(false);

			}
		})
	}

}
