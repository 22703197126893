import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FiltrosService } from '@api/services/filtros.service';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { GroupService } from '@api/services/group.service';
import { FireBase } from '@classes/fireBase';
import { MPlay } from '@models/api-models/plays-api.models';
import { Mygroup } from '@models/group.model';
import { BottomSheetRepetirJugadaComponent } from '@shared/components/bottomSheet/bottomSheetRepetirJugada/bottomSheetRepetirJugada.component';
import { modalYesNoComponent } from '@shared/components/modals/modalYesNo/modalYesNo.component';
import { logEvent } from 'firebase/analytics';
import * as moment from 'moment';
import { now } from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector:'app-play-item-grupo',
    templateUrl:'./play-item-grupo.component.html',
    styleUrls:['./play-item-grupo.component.scss']
})
export class playItemGrupoComponent extends FireBase
{
    selected = false;

  @Input() play: MPlay;
  @Input() abono: boolean = false;
  @Input() grupo: Mygroup;
  @Output() dropAbono: EventEmitter<MPlay> = new EventEmitter();

  constructor(
    private router: Router,
    private routerActive: ActivatedRoute,
    private dialog: MatDialog,
    private grupoService: GroupService,
    private filterService: FiltrosService,
    private toastrService: ToastrService,
    private bottomSheet: MatBottomSheet
  ){
    super()
  }

  styleState(): object {

    switch (this.play.state){
      // En Juego
      case 1: { 
        return { color : '#1FC9FF'};
      }
      // Sorteada
      case 2: {
        return { color : '#FF1F1F'};
      }
      // Premio
      case 3: {
        return { color : '#00BA88'};
      }
    }
    return {};
  }

  public abonarse(){
    const texto = 'La suscripcion consiste en que cada dia que haya sorteo se realizara una jugada con los mismos números o aleatório dependiendo de la selección. Mucha suerte';
    const alerta = this.dialog.open(modalYesNoComponent,{
      panelClass: 'modal-custom',
      data: {
          title: '¡Atencion vas a Suscribirte!',
          texto,
          buttonConfirm: 'Suscribirte',
          btnConfirmColor: 'cool',
          buttonCancel: 'Cancelar',
          showIcon: false,
          showOptionCheck: true
      }
    });

    alerta.afterClosed().subscribe(res=>{
      if (res)
      {
        this.grupoService.abonoPlay(this.play.id, this.grupo.cliente_id).subscribe(res => {
          this.play.abono = res;
          this.filterService.setUpdateAbonos(true)
          let paramFireBase = {
            ITEM_NAME: "btnSuscribe"
          }
          logEvent(this.analytics,"CLICK_ON",paramFireBase)
        });
      }
    });
  }

  public desabono(){
    this.grupoService.desabonoPlay(this.play.id, this.grupo.cliente_id).subscribe(res => {
      this.play.abono = null;

      if(!this.abono)
      {
        this.filterService.setUpdateAbonos(true)
      }
      else
      {
        this.filterService.setUpdateJugadas(true)
        this.dropAbono.emit(this.play)
      }

      this.toastrService.success("Abono cancelado","",{
        positionClass:'toast-bottom-center',
      });
    });
  }

  isSorteado()
  {
    let fechaSorteo = moment(this.play.dateSorteo)

    if(fechaSorteo.diff(now()) <= 0 && this.play.state != 3)
    {
      this.play.state = 2;
      return true
    }
      
    return false;
  }

  openOptions(){
    this.selected = !this.selected;
  }

  repeatPlay(){
    this.bottomSheet.open(BottomSheetRepetirJugadaComponent,{data: {play: this.play, modoGrupo: true}})
  }

    /**
   * Redirect to play detail
   * @returns void 
   */
  public detailPlay(play:MPlay): void{

	console.log('1',play)
	console.log('2',this.routerActive.parent.params)
	console.log('3',this.router.url.split('/'))
	const url = this.router.url.split('/')
	this.routerActive.parent.params.subscribe(res => console.log('suscribe',res))
    this.router.navigate(['/cliente/mis-grupos/detalles',
                          url[4],
                          url[5],
                          'jugada',play.id]);
      
  }
}