import { AfterViewInit, Component, ElementRef, Input, Output, Renderer2, ViewChild } from "@angular/core";
import { ColorGenerator } from "@classes/ColorGenerator";
import { MPerson } from "@models/person.model";

@Component({
    selector: 'app-user-img',
    templateUrl: './user-img.component.html',
    styleUrls: ['user-img.component.scss']
})
export class UserImgComponent implements AfterViewInit
{
	@Input() imagen: string | null = null
    @Input() colorTexto: string | null = null

    @ViewChild("prfpctr") img!: ElementRef

    colorGenerator: ColorGenerator

    constructor(
        private renderer: Renderer2
    ){
        this.colorGenerator = new ColorGenerator()
    }

    ngAfterViewInit(): void {
		if (this.colorTexto != null)
        	this.loadColor()
    }

    private loadColor()
    {
      	let color = this.colorGenerator.strToColor(this.colorTexto)
      	this.renderer.setStyle(this.img.nativeElement,"background",color)
    }
}