export class TipoMovimiento {
	public static tipos = {
		INGRESO: {
			INGRESO_TARJETA: 1,
			INGRESO_PREMIO: 2,
			REPARTO_PREMIO: 5,
			INGRESO_PREMIO_PENYA: 6,
			INGRESO_POR_TRASPASO_PENYA: 7,
			INGRESO_POR_CIERRE_PENYA: 10,
			INGRESO_POR_CIERRE_PENYA_A_NEGRITO: 13,
			INGRESO_POR_CIERRE_CUENTA_A_NEGRITO: 15,
			TRANSFERENCIA_SALDO_PENYA_CLIENTE: 17,
			INGRESO_TRANSFERENCIA_DE_SALDO: 18,
			INGRESO_PREMIO_COMPARTIDO: 19,
			CANCELACION_TRANSFERENCIA_SALDO_PREMIO_A_CUENTA: 20,
			M_INGRESO_SALDO: 51,
			M_INGRESO_SALDO_MASIVO: 58,
			M_INGRESO_SALDO_PERSONAL_PENYA: 61,
			INGRESO_POR_ABANDONO_PENYA: 63,
			INGRESO_POR_TRASPASO_PENYA_AUTOMATIZADO: 66,
			INGRESO_AUTOMATICO_TARJETA: 68,
			ASIGNAR_DINERO_SOBRANTE: 69,
			M_INGRESAR_CANCELACION_JUGADA: 70,
			M_INGRESAR_CANCELACION_APUESTA: 71,
			INGRESO_MIEMBRO_PENAYA_CANCELACION_JUGADA: 72,
			M_INGRESO_CANCELACION_PEDIDO: 73,
			INGRESO_POR_BORRADO_PENYA: 101
		},
		PROMOCION: {
			CODIGO_PROMOCIONAL_ALTA_CLIENTE: 30,
			CODIGO_PROMOCIONAL_EMBAJADOR: 31,
		},
		PAGO: {
			PAGO_PEDIDO: 3,
			PAGO_ABONO: 4,
			TRANSFERENCIA_SALDO_A_PENYA: 8,
			REINTEGRO_POR_CIERRE_PENYA: 9,
			PAGO_IMPUESTOS: 11,
			PAGO_GUINDA: 120,
			REINTEGRO_POR_CIERRE_PENYA_A_NEGRITO: 12,
			REINTEGRO_POR_CIERRE_CUENTA_A_NEGRITO: 14,
			M_RETIRADA_SALDO: 50,
			M_RETIRADA_SALDO_MASIVO: 59,
			M_RETIRADA_SALDO_PERSONAL_PENYA: 60,
			RETIRADA_SALDO_PERSONAL_PENYA_ABANDONO: 62,
			RETIRADA_SALDO_PERSONAL_PENYA_EXPULSION: 64,
			INGRESO_POR_EXPULSION_PENYA: 65,
			RETIRADA_SALDO_PENYA_BORRADO: 100,
			DONACION_PREMIO: 110
		},
		COBRO: {
			TRANSFERENCIA_SALDO_PREMIO_A_CUENTA: 16,
			TRANSFERENCIA_SALDO_PERSONAL_CIERRE_CUENTA: 21,
		},
		INFORMACION: {
			M_CONVERSION_SALDO: 52,
			M_CONVERSION_SALDO_INGRESO: 53,
			M_CONVERSION_SALDO_RETIRADA: 54,
			M_CONVERSION_SALDO_GRUPO: 55,
			M_CONVERSION_SALDO_GRUPO_INGRESO: 56,
			M_CONVERSION_SALDO_GRUPO_RETIRADA: 57,
			M_CONVERSION_SALDO_GRUPO_MASIVO: 74,
			M_PUESTA_A_CERO: 67,
		},
	};
}
