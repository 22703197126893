<div #component class="options-wrapper" @enter>
    <app-auth-header [title]="messageHeader()"></app-auth-header>
    <div class="container">
        <div class="header">
              <h3>¡Bienvenido de nuevo!</h3>
              <p>Inicia sesión para acceder a tu cuenta de Miloto... ¡La 🍀 suerte te espera!</p>
        </div>
        <div class="body">
            <div class="buttons">
                <button class="btn-acceder btn-acceder-secondary" routerLink="/acceso/inicia-sesion" *ngIf="mode=='acceder'">Accede con @email</button>
                <button class="btn-acceder btn-acceder-secondary" routerLink="/acceso/registro" *ngIf="mode=='registrate'">Registrar con @email</button>

				<!--
                <button class="btn-acceder btn-acceder-icon-left" routerLink="/acceso/options/registrate" (click)="loginGoogle()">
                    <img src="/assets/images/pages/options-auth/logo-google.svg"/>
                    <p> {{ messageLoginGoogle() }}</p>
                </button>
				-->
				
                <button class="btn-acceder btn-acceder-icon-left" (click)="loginApple($event)">
                    <img src="/assets/images/icons/appel_icon.png"/>
                    <p> {{ messageLoginApple() }} </p>
                </button>
				<!--
				<button class="btn-acceder btn-acceder-icon-left" routerLink="/acceso/options/registrate" (click)="loginFacebook()">
                    <img src="/assets/images/pages/options-auth/logo-facebook.svg"/>
                    <p>{{ messageLoginFacebook() }}</p>
                </button>
				-->
            </div>
            <div class="descubre">
                <p *ngIf="mode=='acceder'">¿Todavia no tines cuenta? <a (click)="navto()">Registrate</a></p>
                <p *ngIf="mode=='registrate'">¿Ya tienes una cuenta? <a (click)="navto()">Acceder</a></p>
            </div>
        </div>
    </div>
</div>