<div class="automatic-lottery-wrapper">

	<div class="automatic-bet-container card">
		<div class="bet-text">
			<span>Décimos del mismo num.</span>
		</div>
		<div class="bet-elements">
			<div class="decrement-bet" (click)="restarDecimo(lottery[0])" [ngClass]="{'disabled': desactivaAutomatico}">
				<img src="/assets/images/icons/minos.svg" alt="img" />
			</div>

			<div class="bet">
				<span>{{numTickets}}</span>
			</div>
			<div class="increment-bet" (click)="sumarDecimo(lottery[0])" [ngClass]="{'disabled': desactivaAutomatico}">
				<img src="/assets/images/icons/Plus.svg" alt="img">
			</div>
		</div>

	</div>
</div>