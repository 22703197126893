<!--
<div class="spinner" *ngIf="!load">
	<app-spinner forceTo="component-sized"></app-spinner>
</div>
-->
<div class="search-wrapper">
	<div class="categories">
		<div class="option" (click)="selectOption('Número')" [ngClass]="{'selected': selectedOption === 'Número'}">
			<img src="../../../../../../../assets/images/icons/games/button/Search.svg">
			<span class="text" *ngIf="combinedNumbers === '*****'">Número</span>
			<span class="text" *ngIf="combinedNumbers !== '*****'">{{ combinedNumbers }}</span>
		</div>
		<div class="option" (click)="selectOption('Cantidad')" [ngClass]="{'selected': selectedOption === 'Cantidad'}">
			<img src="../../../../../../../assets/images/icons/games/button/albums-outline.svg">
			<span class="text" *ngIf="amountFilter <= 1">Cantidad</span>
			<span class="text" *ngIf="amountFilter > 1">{{ amountFilter }}</span>
		</div>
		<div class="option" (click)="selectOption('Capicua')" [ngClass]="{'selected': selectedOption === 'Capicua'}">
			<img src="../../../../../../../assets/images/icons/games/button/chevron-back.svg">
			<span class="text">Capicua</span>
			<span class="text" *ngIf="isCapicua == true">✓</span>
		</div>
	</div>
	<div class="search" [ngClass]="{'hide': selectedOption !== 'Número'}">
		<div class="clean" (click)="cleanFilter('search')">
			<img class="clean-img" src="../../../../../../../assets/images/icons/games/button/eraser.svg">
			<span class="clean-label">Limpiar</span>
		</div>
		<div class="mid-row">
			<div class="column" *ngFor="let wheel of displayedWheels; let i = index">
				<button class="arrow up" (click)="moveUp(i)"><img
						src="../../../../../../../assets/images/icons/down.svg"></button>
				<div class="separator"></div>
				<div class="numbers" [ngClass]="getClasses(i)">
					<div class="number" *ngFor="let number of wheel">{{ number }}</div>
				</div>
				<div class="separator"></div>
				<button class="arrow down" (click)="moveDown(i)"><img
						src="../../../../../../../assets/images/icons/down.svg"></button>
			</div>

			<div class="initial-state" [ngClass]="{'visible': initialState, 'hidden': !initialState}"><span>-</span>
				Elige un numero <span>-</span></div>
		</div>
		<div class="clean" (click)="cleanFilter('all')">
			<img class="clean-img" src="../../../../../../../assets/images/icons/games/button/eraser.svg">
			<span class="clean-label">Limp. Todo</span>
		</div>
	</div>
	<div class="option-block" [ngClass]="{'hide': selectedOption !== 'Cantidad'}">
		<div class="clean" (click)="cleanFilter('amount')">
			<img class="clean-img" src="../../../../../../../assets/images/icons/games/button/eraser.svg">
			<span class="clean-label">Limpiar</span>
		</div>
		<button class="button-amount" (mousedown)="startChangingAmount(-1)" (mouseup)="stopChangingAmount()"
			[disabled]="amountFilter <= 1">
			<img src="/assets/images/icons/minos.svg" alt="img" />
		</button>
		<div class="amount-label">
			<span>{{amountFilter}}</span>
		</div>
		<button class="button-amount" (mousedown)="startChangingAmount(1)" (mouseup)="stopChangingAmount()">
			<img src="/assets/images/icons/Plus.svg" alt="img">
		</button>
		<div class="clean" (click)="cleanFilter('all')">
			<img class="clean-img" src="../../../../../../../assets/images/icons/games/button/eraser.svg">
			<span class="clean-label">Limp. Todo</span>
		</div>
	</div>
	<div class="option-block" [ngClass]="{'hide': selectedOption !== 'Capicua'}">
		<div class="clean" (click)="cleanFilter('capicua')">
			<img class="clean-img" src="../../../../../../../assets/images/icons/games/button/eraser.svg">
			<span class="clean-label">Limpiar</span>
		</div>
		<span class="capicua">Capicua</span>
		<mat-slide-toggle [(ngModel)]="isCapicua" (click)="onCapicuaCheck()"></mat-slide-toggle>
		<div class="clean" (click)="cleanFilter('all')">
			<img class="clean-img" src="../../../../../../../assets/images/icons/games/button/eraser.svg">
			<span class="clean-label">Limp. Todo</span>
		</div>
	</div>


	<div class="random" (click)="selectRandomNumber()">
		Aleatorio <img src="../../../../../../../assets/images/icons/games/button/Cube 6.svg">
	</div>
</div>

<cdk-virtual-scroll-viewport *ngIf="searchResults && searchResults.length" class="numbers-list" (scrolledIndexChange)="onScroll($event)" itemSize="50">
	<div *cdkVirtualFor="let number of searchResults" class="number-item" [ngClass]="{'selected': number.cantidad_compra > 0}">
		<img [src]="image ? image : './../../../../../assets/images/background/empty_ticket.svg'" class="number-img">
		<div class="number-label">
			<span class="number">Nº {{number.numero}}</span>
			<span class="amount">Quedan {{number.cantidad}}</span>
		</div>
		<div class="number-buttons">
			<button (click)="restarDecimo(number)">
				<img src="/assets/images/icons/minos.svg" alt="img" />
			</button>
			<div class="quantity-label">
				<span>{{number.cantidad_compra | number:'1.0-0'}}</span>
			</div>
			<button (click)="sumarDecimo(number)">
				<img src="/assets/images/icons/Plus.svg" alt="img">
			</button>
		</div>
	</div>
	<div *ngIf="!noMoreResults"class="number-item"><mat-spinner [diameter]="30"></mat-spinner>
	</div>
</cdk-virtual-scroll-viewport>


<!---
<div class="manual-play-lottery-wrapper" [style.display]="load ? 'block' : 'none'">
	<cdk-virtual-scroll-viewport #scroll itemSize="15" class="scrollLottery">

		<div class="numbers-list">
			<div class="number-detail" [ngClass]="{'mb-1': last}" *ngFor="let l of searchNumbers; let last = last">

				<div class="number-image">
					<img class="image" [src]="url" alt="img">
				</div>

				<div class="number-data">
					<h3>{{l.numero}}</h3>
					<p>Quedan {{l.cantidad}}</p>
				</div>

				<div class="number-layout">
					<button class="button-less" (click)="restarDecimo(l)">
						<img src="/assets/images/icons/minos.svg" alt="img" />
					</button>
					<div class="quantity-label">
						<span>{{l.cantidad_compra}}</span>
					</div>
					<button class="button-plus" (click)="sumarDecimo(l)">
						<img src="/assets/images/icons/Plus.svg" alt="img">
					</button>
				</div>

			</div>

			<div class="bottom-spinner">
				<mat-progress-spinner class="spinner" *ngIf="spinnerBottomLoadVisibility" [mode]="'indeterminate'"
					[diameter]="35"> </mat-progress-spinner>
			</div>
		</div>
	</cdk-virtual-scroll-viewport>
</div>
-->