<div class="center-point">
    <div class="modal">
        <div class="header">
            <span>¿Quieres que te avisemos cuando tengamos el número </span>
            <span>{{ data.num }} ?</span>
        </div>
        <div class="body">
            <div class="buttons">
                <button style="background-color: #38B64F;" (click)="addAlert()">SÍ</button>
                <button style="background-color: #1e1e24;" (click)="closeDialog()">NO</button>
            </div>
        </div>
    </div>
</div>