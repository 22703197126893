<div class="bottomSheetContainer" (click)="dimiss()">
  <div class="button">
    <div class="btn-ln">
      <div class="line">

      </div>
      <h4>Seguimiento Jugada</h4>
    </div>
  </div>
  <div class="container">
    <mat-vertical-stepper orientation="vertical" class="stepper-margin">
        <mat-step *ngFor="let seguimiento of listaSeguimiento">
          <ng-template matStepLabel>

            <mat-card appearance="outlined" class="card">
              <div class="column">
                <p>{{seguimiento.fecha}}</p>
                <p>{{seguimiento.nombre}}</p>
              </div>
              <div class="buttonColumn">
                <button *ngIf="seguimiento.url && !isPedidoSeguimiento(seguimiento)" mat-button (click)="goToLink(seguimiento.url)">ver</button>
                <button *ngIf="seguimiento.url && isPedidoSeguimiento(seguimiento)" mat-button (click)="goToLink(seguimiento.url)">Seguimiento</button>
              </div>
            </mat-card>

            
          </ng-template>
        </mat-step>
      </mat-vertical-stepper>
  </div>
</div>
