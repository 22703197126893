<div class="spinner-wrapper" *ngIf="loading">
	<app-spinner forceTo='component-sized'></app-spinner>
</div>
<div class="content-list">
	<div class="plays-list" [ngClass]="{'grupo': modeGroup}">
		<cdk-virtual-scroll-viewport #scroll itemSize="15" class="scroll">
			<ng-container *ngIf="finalPlays?.length > 0">
				<div class="plays-row" *ngFor="let play of finalPlays; let i = index; let last = last">
					<span class="plays-date" *ngIf="printDate(i)">{{play.date}}</span>
					<ng-container *ngIf="modeGroup; else noGroup">
						<app-play [play]="play" [device]="this.device" [abono]="abonos" (dropAbono)="dropAbonoList($event)"
							[grupo]="grupo" [ngClass]="{'mb5': last}"></app-play>
					</ng-container>
					<ng-template #noGroup>
						<app-play [play]="play" [device]="this.device" [abono]="abonos" (dropAbono)="dropAbonoList($event)"
							[ngClass]="{'mb5': last}"></app-play>
					</ng-template>
				</div>
			</ng-container>
			<div class="no-plays" *ngIf="finalPlays?.length == 0 && loading == false">
				<h3 class="no-plays-text">
					Aquí parece que todavía no hay mucho que ver
				</h3>
				<img class="image" src="/assets/images/icons/plays-list/no-plays.svg" />
			</div>
			<div class="bottom-spinner">
				<mat-progress-spinner class="spinner" *ngIf="spinnerBottomLoadVisibility" [mode]="'indeterminate'"
					[diameter]="35"> </mat-progress-spinner>
			</div>
		</cdk-virtual-scroll-viewport>
		<br>
	</div>
</div>