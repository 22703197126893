import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { AfterViewChecked, Component, Inject, Renderer2 } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { CustomVirtualScroll } from "@classes/CustomVritualScoll";
import { MPlay } from "@models/api-models/plays-api.models";
import { ResultDraw } from '@models/resultDraw.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationStateService } from '@shared/services/application-state.service';
import { CCoreComponent } from '@classes/core/core-component.class';
import { PlaysAPIService } from '@api/services/games/plays-api.service';
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "@api/services/authentication.service";
import { EAuthenticationAvailableOptions } from '@enums/common/authentication-options.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialUser } from "@abacritt/angularx-social-login";;
import { UserApiService } from "@api/services/user.service";

@Component({
	selector: 'app-bottomSheetAceptarCompartida',
	templateUrl: './bottomSheetAceptarCompartida.component.html',
	styleUrls: ['./bottomSheetAceptarCompartida.component.scss']
	, providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScroll }]
})
export class bottomSheetAceptarCompartidaComponent extends CCoreComponent {


	loginForm: FormGroup;
	registerForm: FormGroup;
	loggedForm: FormGroup;

	isLogged: false;
	apiResponse: any;
	codigo: any;
	socialUser: SocialUser;
	id: any;
	isLogin = false;
	accepted: Boolean = false;
	checked: Boolean = false
	public first: boolean = true;
	public hide: boolean = true;
	constructor(
		private bottomSheetAceptarJugada: MatBottomSheetRef<bottomSheetAceptarCompartidaComponent>,
		applicationStateService: ApplicationStateService,
		private playsAPIService: PlaysAPIService,
		private toastr: ToastrService,
		private authService: AuthenticationService,
		private userService: UserApiService,
		private router: Router,
		@Inject(MAT_BOTTOM_SHEET_DATA) public data: { isLogged, apiResponse, codigo, socialUser },
		private renderer: Renderer2
	) {

		super(applicationStateService);
		this.isLogged = data.isLogged;
		this.apiResponse = data.apiResponse;
		this.codigo = data.codigo;
		if (this.data.socialUser != undefined)
			this.socialUser = data.socialUser;

		bottomSheetAceptarJugada.afterDismissed().subscribe(() => {
			if (!this.accepted)
				this.router.navigate(['/']);
		});
	}

	ngAfterViewChecked() {
		this.setRandomColor();
	}

	ngOnInit(): void {


		this.loginForm = new FormGroup({
			emailLogin: new FormControl('', [Validators.required, Validators.email]),
			passwordLogin: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_\\-#=+%$?&@!\\.*:\/]).{8,}$')]),
			aceptarLogin: new FormControl('', [Validators.required]),
			type: new FormControl(1, [Validators.required])
		});

		this.registerForm = new FormGroup({
			nameRegister: new FormControl('', [Validators.required]),
			//emailRegister: new FormControl({value: this.apiResponse.data.email_receptor, disabled: true}),
			emailRegister: new FormControl('', [Validators.required, Validators.email]),
			passwordRegister: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_\\-#=+%$?&@!\\.*:\/]).{8,}$')]),
			aceptarRegister: new FormControl('', [Validators.required]),
			type: new FormControl(2, [Validators.required])
		});

		//this.registerForm.controls.emailRegister.disable();

		this.loggedForm = new FormGroup({
			aceptarLogged: new FormControl('', [Validators.required]),
			type: new FormControl(1, [Validators.required])
		});

	}

	get emailLogin() { return this.loginForm.get('emailLogin'); }
	get passwordLogin() { return this.loginForm.get('passwordLogin'); }
	get aceptarLogin() { return this.loginForm.get('aceptarLogin'); }

	get nameRegister() { return this.registerForm.get('nameRegister'); }
	get emailRegister() { return this.registerForm.get('emailRegister'); }
	get passwordRegister() { return this.registerForm.get('passwordRegister'); }
	get aceptarRegister() { return this.registerForm.get('aceptarRegister'); }

	get passwordRegisterInvalid(): boolean | undefined {
		return this.registerForm.get('passwordRegister')?.invalid && this.registerForm.get('passwordRegister')?.touched;
	}


	dimiss(): void {
		this.bottomSheetAceptarJugada.dismiss();
	}

	nofirst() {
		this.first = false;
	}

	hidePass() {
		this.hide = !this.hide;
	}

	getFirstLetter(letra: string) {
		return letra.toLocaleUpperCase()[0];
	}

	setRandomColor() {
		document.querySelectorAll("div[class='letter']").forEach(x => {
			this.renderer.setStyle(x, "background-color", this.generateColors());
		})
	}

	private generateColors() {
		let letters = '0123456789ABCDEF';
		let color = '#';

		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}

		return color;
	}


	public loginAcceptSharedPlay(form): void {

		this.isLogin = true

		let data = {
			'email': form.controls.emailLogin.value,
			'password': form.controls.passwordLogin.value,
			'type': form.controls.type.value
		}
		this.playsAPIService.acceptSharedPlay(data, this.codigo).subscribe(res => {

			if (res.status == 200) {
				this.accepted = true
				this.dimiss()
				this.authService.saveToken(res.data.token, EAuthenticationAvailableOptions.JWT_LOGIN);
				this.userService.getDataUser();
				this.userService.usuarioObservable.subscribe( response => {
					if(response)
						this.router.navigate(["/cliente/jugadas/"])
				}, (error) => {
					console.log(error);
				});

			} else {
				this.isLogin = false
				this.toastr.error('Ha ocurrido un error al aceptar la jugada, resiva los datos introducidos');
			}


		});
	}
	public registerAcceptSharedPlay(form): void {

		this.isLogin = true

		let data = {
			'name': form.controls.nameRegister.value,
			'email': form.controls.emailRegister.value,
			'password': form.controls.passwordRegister.value,
			'type': form.controls.type.value
		}

		this.playsAPIService.acceptSharedPlay(data, this.codigo).subscribe(res => {
			if (res.status == 200) {
				this.accepted = true
				this.dimiss()
				this.authService.saveToken(res.data.token, EAuthenticationAvailableOptions.JWT_LOGIN);
				this.userService.getDataUser();
				this.userService.usuarioObservable.subscribe(response => {
					if(response)
						this.router.navigate(["/cliente/jugadas/"])
				}, (error) => {
					console.log(error);
				});
			} else {
				this.isLogin = false
				this.toastr.error('Ha ocurrido un error al aceptar la jugada');
			}

		});
	}
	public googleAcceptSharedPlay(): void {


		this.isLogin = true

		this.authService.loginGoogle().then(social => {

			this.socialUser = social;
			this.playsAPIService.socialAcceptSharedPlay('g', this.socialUser.authToken, this.codigo).subscribe(res => {

				if (res.status == 200) {
					this.accepted = true
					this.dimiss()
					this.authService.saveToken(res.data.token, EAuthenticationAvailableOptions.GOOGLE_LOGIN);
					this.userService.getDataUser();					
					this.userService.usuarioObservable.subscribe(response => {					
						if(response)
							this.router.navigate(["/cliente/jugadas/"])
					}, (error) => {
						console.log(error);
					});
				} else {
					this.isLogin = false
					this.toastr.error('Ha ocurrido un error al aceptar la jugada');
				}


			});

		}).catch(err => {

			this.toastr.error('Ha ocurrido un error al aceptar la jugada');

		});


	}

	public facebookAcceptSharedPlay(): void {


		this.isLogin = true

		this.authService.loginFacebook().then(social => {

			this.socialUser = social;
			this.playsAPIService.socialAcceptSharedPlay('f', this.socialUser.authToken, this.codigo).subscribe(res => {

				if (res.status == 200) {
					this.accepted = true
					this.dimiss()
					this.authService.saveToken(res.data.token, EAuthenticationAvailableOptions.FACEBOOK_LOGIN);
					this.userService.getDataUser();
					this.userService.usuarioObservable.subscribe(response => {
						if(response)
							this.router.navigate(["/cliente/jugadas/"])
					}, (error) => {
						console.log(error);
					});
				} else {
					this.isLogin = false
					this.toastr.error('Ha ocurrido un error al aceptar la jugada');
				}
			});
		}).catch(err => {

			this.toastr.error('Ha ocurrido un error al aceptar la jugada');

		});


	}

	public logged_acceptSharedPlay(): void {

		this.isLogin = true

		this.playsAPIService.acceptSharedPlay({}, this.codigo).subscribe(res => {
			if (res.status == 200) {
				this.accepted = true
				this.dimiss()
				this.userService.usuarioObservable.subscribe(response => {
					if(response)
						this.router.navigate(["/cliente/jugadas/"])
				}, (error) => {
					console.log(error);
				});
			} else {
				this.isLogin = false
				this.toastr.error('Ha ocurrido un error al aceptar la jugada');
			}
		});;
	}

}