import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { GroupService } from '@api/services/group.service';
import { HeaderService } from '@api/services/header.service';
import { UserApiService } from '@api/services/user.service';
import { MovimientoDetails } from '@models/MovimientoDetails.model';
import { MClient } from '@models/client.model';
import { Mygroup } from '@models/group.model';
import { MPerson } from '@models/person.model';
import { TipoMovimiento } from '@models/tipos-movimiento.model';
import { BottomSheetMovimientos } from '@shared/components/bottomSheet/bottomSheetMovimiento/bottomSheetMovimiento.component';


@Component({
	selector: 'app-single-movement',
	templateUrl: './single-movement.component.html',
	styleUrls: [
		'./single-movement.component.scss',
	]
})
export class SingleMovementComponent implements OnInit, OnChanges {

	@Input() movimientos: any[] = [];
	@Input() cliente?: MClient = null;
	@Input() grupo?: Mygroup = null;

	public title: Array<String> = [];

	public modeGroup: boolean = false;

	public actividad: any[] = [];
	public selectedCategory: string | null = null;

	@ViewChild('scrollContent', { static: false }) scrollContent!: ElementRef;	

	isDown = false;
	startX = 0;
	scrollLeft = 0;

	public saldo: any;
	public saldoMiembro: any;

	public person: MPerson = null;

	public imagen;
	public cliente_id;
	public nombreUser;
	public firstLetterName;

	constructor(private router: Router, 
				private bottomShet: MatBottomSheet, 
				private headerService: HeaderService,
				private groupService: GroupService,
				private userService: UserApiService) {
	}

	ngOnInit(): void {
		this.headerService.modeGrupoCoolOb.subscribe(res => { 
			this.modeGroup = res;
			if (this.modeGroup) {
				if (this.grupo == null) {
					this.groupService.getGroup.subscribe(res => {
						this.grupo = res;
					});
				}
				if (this.router.url.includes('saldo-persona')) {
					if (this.cliente == null) {
						this.userService.usuarioObservable.subscribe(res => {
							if(res) {
								this.cliente = res.client;
							}
						});
					}
					let member = this.grupo.miembros.filter((x:any) => x.cliente_id == this.cliente.id ? x : '')[0];
					this.saldo = Number(member.saldo.saldo_tarjeta) + Number(member.saldo.saldo_premios) + Number(member.saldo.saldo_no_disponible);
				}
				else {
					if (this.grupo == null) {
						this.groupService.getGroup.subscribe(res => {
							this.grupo = res;
						});
					}
					this.userService.usuarioObservable.subscribe(res => {
						this.person = res;
						this.imagen = res.imagen;
						this.cliente_id = res.client.id;
						this.nombreUser = res.name + " " + res.surname;
						this.firstLetterName = res.name[0].toUpperCase();

						let member = this.grupo.miembros.filter((x:any) => x.cliente_id == this.cliente_id ? x : '')[0];
						this.saldoMiembro = Number(member.saldo.saldo_tarjeta) + Number(member.saldo.saldo_premios) + Number(member.saldo.saldo_no_disponible);
					})
					this.saldo = Number(this.grupo.saldo.saldo_tarjeta)+Number(this.grupo.saldo.saldo_premios) + Number(this.grupo.saldo.saldo_no_disponible);
				}
			}
			else {
				if (this.cliente == null) {
					this.userService.usuarioObservable.subscribe(res => {
						if(res) {
							this.cliente = res.client;
							this.saldo = this.cliente.getSaldoTotal();
						}
					});
				}
				else {
					this.saldo = this.cliente.getSaldoTotal();
				}
			}
		});
	}

	@HostListener('mousedown', ['$event'])
	onMouseDown(event: MouseEvent): void {
		this.isDown = true;
		const target = this.scrollContent.nativeElement as HTMLElement;
		this.startX = event.pageX - target.offsetLeft;
		this.scrollLeft = target.scrollLeft;
		target.style.cursor = 'grabbing';
	}

	@HostListener('mouseleave')
	onMouseLeave(): void {
		this.isDown = false;
		const target = this.scrollContent.nativeElement as HTMLElement;
		target.style.cursor = 'grab';
	}

	@HostListener('mouseup')
	onMouseUp(): void {
		this.isDown = false;
		const target = this.scrollContent.nativeElement as HTMLElement;
		target.style.cursor = 'grab';
	}

	@HostListener('mousemove', ['$event'])
	onMouseMove(event: MouseEvent): void {
		if (!this.isDown) return;
		event.preventDefault();
		const target = this.scrollContent.nativeElement as HTMLElement;
		const x = event.pageX - target.offsetLeft;
		const walk = (x - this.startX) * 2;
		target.scrollLeft = this.scrollLeft - walk;
	}

	ngOnChanges(): void {
		this.filterActividad();
		this.changeTitles();
	}

	changeTitles() {
		this.title = [];
		if (this.actividad != undefined) {
			for (let movimiento of this.actividad) {
				switch (this.getCategoryById(movimiento.tipo_movimiento)) {
					case 'INGRESO':
						this.title.push('Ingreso');
						break;
					case 'COBRO':
						this.title.push('Transferencia');
						break;
					case 'PAGO':
						this.title.push('Pago');
						break;
					case 'PROMOCION':
						this.title.push('Promoción');
						break;
					case 'INFORMACION':
						this.title.push('Conversión');
						break;
					default:
						this.title.push((!this.comprobarCobro(movimiento.importe) ? (movimiento.tipo_movimiento == 2 ? 'Premio' : 'Ingreso') : 'Cobro'));
						break;
				}
			}
		}
	}

	comprobarCobro(saldo: any) {
		if (saldo >= 0)
			return false;
		return true;
	}

	ingresarBtn() {
		if (this.modeGroup && this.cliente != null) {
			this.router.navigate(['/','cliente','mis-grupos','detalles',this.grupo.cliente_id,this.grupo.nombre,'ingresar']);
		}
		else {
			this.router.navigate(['/', 'cliente', 'ingresar']);
		}
	}

	retirarBtn() {
		if (this.modeGroup && this.cliente != null) {
			this.router.navigate(['/','cliente','mis-grupos','detalles',this.grupo.cliente_id,this.grupo.nombre,'cobrar']);
		}
		else {
			this.router.navigate(['/', 'cliente', 'cobrar']);
		}
	}

	verSaldo()
    {
        this.headerService.set_rutaBack(this.router.url)
        this.router.navigate(['/','cliente','mis-grupos','detalles',this.grupo.cliente_id,this.grupo.nombre,'saldo-persona',this.cliente_id]);
    }

	openSheet(movimiento: any) {
		let movimientoDetails = new MovimientoDetails()
		movimientoDetails.concepto = movimiento.concepto_api
		movimientoDetails.importe = movimiento.importe

		movimientoDetails.fecha = movimiento.fecha

		movimientoDetails.tipo_movimiento = movimiento.importe > 0 ? 'Ingreso Realizado' : 'Cobro efectuado';

		switch (movimiento.tipo_movimiento) {
			case 2:
				movimientoDetails.tipo_movimiento = 'Premio recibido';
				break;
			case 6:
				movimientoDetails.tipo_movimiento = 'Premio recibido';
				break;
			case 52:
				movimientoDetails.tipo_movimiento = 'Conversión';
				break;
			case 74:
				movimientoDetails.tipo_movimiento = 'Conversión';
				break;
			case 16:
				movimientoDetails.tipo_movimiento = 'Transferencia efectuada';
				break;
			default:
				this.title.push(!this.comprobarCobro(movimiento.importe) ? (movimiento.tipo_movimiento == 2 ? 'Premio' : 'Ingreso') : 'Cobro');
				break;
		}

		this.bottomShet.open(BottomSheetMovimientos, { data: { movimiento: movimientoDetails } })
	}

	onCategoryClick(category: string): void {
		this.selectedCategory = category;
		this.filterActividad();
		this.changeTitles();
	}

	private filterActividad(): void {
		if (this.selectedCategory == null) {
		  	this.actividad = this.movimientos;
		  	return;
		}
	
		const ids = Object.values(TipoMovimiento.tipos[this.selectedCategory] || {});
		this.actividad = this.movimientos.filter(movimiento =>
		  	ids.includes(movimiento.tipo_movimiento)
		);
	}

	getCategoryById(id) {
		for (let category in TipoMovimiento.tipos) {
			for (let key in TipoMovimiento.tipos[category]) {
				if (TipoMovimiento.tipos[category][key] === id) {
					return category;
				}
			}
		}
		return null;
	}
}
