import { Component, OnDestroy, ViewChild, Input, NgZone, AfterViewInit, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PlaysService } from '@api/services/play.service';
import { DialogService } from '@api/services/dialog.service';
import { faHeart, faRedo, faShare, faSlidersH, faStar } from '@fortawesome/free-solid-svg-icons';
import { MPlay } from '@models/api-models/plays-api.models';
import { TemplateFilterService } from '@shared/services/template-filter.service';
import { GroupService } from '@api/services/group.service';
import { Mygroup } from '@models/group.model';
import { Subscription } from 'rxjs';
import { filter, map, pairwise, take, throttleTime } from 'rxjs/operators';
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { CustomVirtualScroll } from '@classes/CustomVritualScoll';
import { PlaysFilter } from '@models/playsFilter';
import { FiltrosService } from '@api/services/filtros.service';

@Component({
    selector:'app-play-list-grupo',
    templateUrl:'./play-list-grupo.component.html',
    styleUrls:['./play-list-grupo.component.scss'],
    providers:[{provide:VIRTUAL_SCROLL_STRATEGY, useClass:CustomVirtualScroll}]
})
export class playListGrupoComponent implements OnDestroy, AfterViewInit, OnInit
{
  //Variables
  public selectedValue: String;
  public filterPlay: String;
  public filterContainer: boolean;
  public plays: any[];
  public finalPlays: Array<MPlay> = [];
  public repPlay: MPlay;
  public grupo: Mygroup = new Mygroup();

  private jugadasUpdateOb: Subscription
  private abonosUpdateOb: Subscription
  private filtrosOb: Subscription

  public filtros = new PlaysFilter()
  public spinnerBottomLoadVisibility: boolean = false
  public loading = false;
  private lasPage = false

  private page: number = 1

  @ViewChild("scroll") scroller: CdkVirtualScrollViewport;
  @Input() abonos: boolean = false;

  //Icons
  public iconHeart = faHeart;
  public iconRepeat = faRedo;
  public iconShare = faShare;
  public iconFilter = faSlidersH;
  public iconStar = faStar;

  constructor(
    private gurpoService: GroupService,
    private AuxPlaysService: PlaysService,
    private filterService: TemplateFilterService,
    private router: Router,
    private routerActive: ActivatedRoute,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private dialogSercive: DialogService,
    private filtrosService: FiltrosService
    
  ){

    this.plays = [];
  }

  /**
   * 
   * Load the plays from api
   * @returns void
   */
  public getPlays(page = 1): void{
    this.gurpoService.getGroup.pipe(take(1)).subscribe(res=>{
        this.grupo = res
        this.gurpoService.getPlaysApi(this.grupo, page, this.filtros,this.abonos)
        
        this.gurpoService.playsOb$
            .subscribe((res: any[]) => {
              this.spinnerBottomLoadVisibility = false;
              if(res.length > 0)
              {
                this.plays = res;
                this.loadPlays();
                this.loading = false;
        
                this.finalPlays = this.finalPlays.filter((x) => {return x.idGame != 9})
              }
              else
              {
                this.loading = false;
                this.lasPage = true
              }
            },
            err => {
              console.log(err)
            });
    })

  }

  ngOnInit(): void {
    this.filtrosOb = this.filtrosService.filtrosOb.subscribe(res=>{
      this.page = 1;
      this.filtros = res;
      this.loading = true
      this.lasPage = false
      this.finalPlays = [];
      this.getPlays(this.page)
    })
  }

  ngAfterViewInit(): void {
    this.onScroll();
    this.reloadAbonos();
    this.reloadPlays();
  }

  public onScroll()
  {
    this.scroller.elementScrolled().pipe(
      map(()=> this.scroller.measureScrollOffset('bottom')),
      pairwise(),
      filter(([y1,y2])=> (y2 < y1 && y2 > 100)),
      throttleTime(1500)
    ).subscribe(()=>{
      this.ngZone.run(()=>{
        if(!this.lasPage)
        {
          this.page ++;
        
          if(this.page > 1)
            this.spinnerBottomLoadVisibility = true;
  
          this.getPlays(this.page);
        }
      })
    })
  }

  public dropAbonoList(abono: MPlay)
  {
    this.finalPlays = this.finalPlays.filter(x=> x.id != abono.id)
  }

  private reloadAbonos()
  {
    if(this.abonos)
    {
      this.abonosUpdateOb = this.filtrosService.AbonosUpdateOb.subscribe(res=>{
        if(res)
        {
          this.page = 1
          this.finalPlays = []
          this.loading = true
          this.lasPage = false
          this.getPlays()
          this.filtrosService.setUpdateAbonos(false)
        }
      })
    }
  }

  private reloadPlays()
  {
    if(!this.abonos)
    {
      this.jugadasUpdateOb = this.filtrosService.jugadasUpdateOb.subscribe(res=>{
        if(res)
        {
          this.page = 1
          this.finalPlays = []
          this.loading = true
          this.lasPage = false
          this.getPlays()
          this.filtrosService.setUpdateJugadas(false)
        }
      })
    }
  }

  /**
   * 
   * Load the plays to show on template
   * @returns void
   */
  private loadPlays(): void{
    this.finalPlays.push(...this.AuxPlaysService.extractInfoApi(this.plays));
  }

  /**
   * Returns filtered plays by filterPlay
   * @returns void
   */
  public filterPlays(): void{

      let auxFinalPlay = [];
      if(this.filterPlay == 'Lotería Nacional'){
        auxFinalPlay =  this.filterService.filterGames(this.finalPlays, 'name', 'Lotería de Navidad');
      }
      this.loadPlays();
      this.finalPlays = this.filterService.filterGames(this.finalPlays, 'name', this.filterPlay).concat(auxFinalPlay);

  }

  /**
   * Returns all plays without filtering
   * @returns void
   */
  public filterAll(): void{

      this.filterPlay = 'Todos';
      this.loadPlays();

  }

  /**
   * Load the all bought plays and 
   * put in section bought with filter container closed
   * @returns void
   */
  public setUpBought(): void{

      this.selectedValue = 'bought';
      this.filterPlay = 'Todos';
      this.filterContainer = false;
      this.loadPlays();

  }
  
  /**
   * Load the all scanned plays and 
   * put in section scanned with filter container closed
   * @returns void
   */
  public setUpScanned(): void{

      this.selectedValue = 'scanned';
      this.filterPlay = 'Todos';
      this.filterContainer = false;
      //this.loadResults();

  }

  /**
   * Redirect to play detail
   * @returns void 
   */
  public detailPlay(play:MPlay): void{

    this.router.navigate(['/cliente/mis-grupos/detalles',
                          this.routerActive.snapshot.parent.params.grupo_id,
                          this.routerActive.snapshot.parent.params.nombre,
                          'jugada',play.id]);
      
  }

  printDate(index: number){

    if (index === 0){
      return true;
    }

    if (this.finalPlays[index].date === this.finalPlays[index - 1].date){
      return false;
    }

    return true;

  }

  ngOnDestroy()
  {
    if(this.jugadasUpdateOb)
      this.jugadasUpdateOb.unsubscribe()

    if(this.abonosUpdateOb)
      this.abonosUpdateOb.unsubscribe()
      
    if(this.filtrosOb)
      this.filtrosOb.unsubscribe()
  }

}