import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "@api/services/authentication.service";
import { FooterService } from "@api/services/footer.service";
import { GroupService } from "@api/services/group.service";
import { HeaderService } from "@api/services/header.service";
import { playFreeService } from "@api/services/playFree.service";
import { UserApiService } from "@api/services/user.service";
import { BetService } from "@modules/games/bet.service";
import { ToastrService } from "ngx-toastr";
import { GamesFooterComponent } from "../../footer/games-footer.component";
import { ConfigurationApiService } from "@api/services/configuration.service";
import { azureConfigService } from "@api/services/azureconfig.service";
import { MPerson } from "@models/person.model";

@Component({
	selector: 'app-direccion-compra-view',
	templateUrl: './direccion-compra-view.component.html',
	styleUrls: ['./direccion-compra-view.component.scss']
})
export class direccionCompraViewComponent extends GamesFooterComponent implements OnInit, OnDestroy {
	public userDates: any;
	public sale = false;
	public user: MPerson;

	constructor(protected userService: UserApiService,
		protected betService: BetService,
		protected router: Router,
		protected activateRouter: ActivatedRoute,
		protected toastrService: ToastrService,
		protected dialog: MatDialog,
		protected playFreeService: playFreeService,
		protected headerSerivce: HeaderService,
		protected footerService: FooterService,
		protected grupoService: GroupService,
		protected authenticationService: AuthenticationService,
		protected configService: ConfigurationApiService,
		protected azureService: azureConfigService
	) {

		super(betService,
			router,
			activateRouter,
			toastrService,
			playFreeService,
			dialog,
			userService,
			headerSerivce,
			footerService,
			grupoService,
			authenticationService,
			configService,
			azureService
		)

		this.userDates = {
			"direccion": "",
			"codigo_postal": "",
			"ciudad": "",
			"provincia": ""
		}
	}

	ngOnInit() {
		this.loadAddresCliente();
	}

	loadAddresCliente() {
		this.userService.usuarioObservable.subscribe(x => {
			if (x) {
				this.user = x;
				this.userDates.direccion = x.adress;
				this.userDates.codigo_postal = x.cp;
				this.userDates.ciudad = x.city;
				this.userDates.provincia = x.province;
				this.userDates.telefono = x.phone;
			}
		});
	}

	updateUserData() {
		this.userService.updateDataUser(this.userDates).subscribe(x => { });
	}

	setUsuario() {
		this.user.adress = this.userDates.direccion;
		this.user.cp = this.userDates.codigo_postal;
		this.user.city = this.userDates.ciudad;
		this.user.province = this.userDates.provincia;
		this.user.phone = this.userDates.telefono;
		this.userService.setUsuario(this.user);
	}

	comprar() {
		if (this.userDates.direccion &&
			this.userDates.codigo_postal &&
			this.userDates.ciudad &&
			this.userDates.provincia &&
			this.userDates.telefono) {

			this.sale = true
			this.updateUserData()
			this.betService.observableLoading.subscribe((x: boolean) => {
				this.sale = x;
			});

			this.setUsuario();
			super.playBetAux();
		}
		else {
			this.toastrService.error("Debes rellenar la informacion de envio para poder completar la compra")
			this.sale = false
		}

	}

	ngOnDestroy() {
		this.betService.clearData();
		this.betService.clearSendMode();
		this.betService.setEmpresa(null)
	}
}