<div class="bottomSheetContainer" (click)="dimiss()">
    <div class="button">
        <div class="btn-ln">
            <div class="line">

            </div>
            
        </div>
    </div>
    <div class="container">

        <div class="row mt-50">
            <h4>{{movimiento.tipo_movimiento}}</h4>
            <h3 [ngClass]="{'ingreso': movimiento.importe > 0, 'cobro': movimiento.importe < 0 }"> {{(movimiento.importe > 0? '+':'' )}} {{ movimiento.importe | currency: 'EUR'}}</h3>
        </div>

        <div class="row">
            <p><strong>Concepto</strong></p>
            <p>{{movimiento.concepto}}</p>
        </div>
        
        <div class="row">
            <p><strong>Fecha</strong></p>
            <p>{{movimiento.fecha | date: 'dd/MM/Y | H:mm'}}</p>
        </div>
        <div class="space"></div>
    </div>
</div>