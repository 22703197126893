import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MClient } from '@models/client.model';
import { MPerson } from '@models/person.model';
import { ApiRouterService } from '@shared/services/api-router.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { ReponseStandar } from "@models/api-models/response-standar.model";
import { shareReplay, tap } from 'rxjs/operators';
import { error } from 'console';
import { AzureAD } from '@models/azuread.model';
import { MatDialog } from '@angular/material/dialog';
import { modalLegalComponent } from '@shared/components/modals/modalLegal/modalLegal.component';

/**
 * User API service. Provides the app with user data from the API.
 */
@Injectable({
	providedIn: 'root'
})
export class UserApiService extends ApiRouterService {

	private persona: MPerson;
	public usuarioObservable: Observable<MPerson>;
	private usuarioSubject: BehaviorSubject<MPerson>;

	private azurelogin: AzureAD;
	public azureloginObservable: Observable<AzureAD>;
	private azureloginSubject: BehaviorSubject<AzureAD>;

	private legal: boolean;
	public legalObservable: Observable<boolean>;
	private legalSubject: BehaviorSubject<boolean>;

	public movimientosObserver$: Observable<any>

	constructor(
		private http: HttpClient,
		private authService: AuthenticationService,
		private dialog: MatDialog
	) {

		super();

		this.usuarioSubject = new BehaviorSubject<MPerson>(null);
		this.usuarioObservable = this.usuarioSubject.asObservable();

		this.azureloginSubject = new BehaviorSubject<AzureAD>(new AzureAD);
		this.azureloginObservable = this.azureloginSubject.asObservable();

		this.legalSubject = new BehaviorSubject<boolean>(false);
		this.legalObservable = this.legalSubject.asObservable();

	}


	/**
	 * Set the loading state
	 * @param saldo Saldo of the user
	 */
	public setSaldo(saldo: number): void 
	{
		var p = this.persona

		p.client.balanceCc = String(saldo)
		p.client.balancePrizes = String(0)

		this.persona = p

		this.usuarioSubject.next(this.persona);
	}

	public setlegal(): void 
	{
		this.legalSubject.next(true);
	}

	/**
	 * Returns all content of plays from api
	 * @returns Callback returned from the API.
	 */
	public getDataUser(): void {

		let token = this.authService.loadToken();
		if (!token){
			return
		}
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		
		this.http.get(`${this.URL}personas/${tokenJWT.idClient}?platform=WEB`, httpOptions)
			.pipe(shareReplay(), tap((res => this.extractInfoUserOfApi(res))))
			.subscribe((() => {
				this.legalObservable.subscribe(res => {
					if (res != true)
						this.checkLegal();				
				});
			}), error => {
				console.log("error to obtain user", error)
			})
	}

	public setUsuario(usuario: MPerson): void {

		this.persona = usuario;
		this.usuarioSubject.next(this.persona);

	}

	public setLoginAzure(azuread: AzureAD): void {
		this.azurelogin = azuread;
		this.azureloginSubject.next(this.azurelogin);
	}

	/**
	 * Send to api the data to update.
	 * @param bodyUser User data Model.
	 * @returns Callback returned from the API.
	 *
	 */
	public updateDataUser(bodyUser: any): Observable<any> {

		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		return this.http.put(`${this.URL}personas/${tokenJWT.idClient}`, bodyUser, httpOptions);

	}

	/**
	 * Extract the user info of API response.
	 * @param response Response from the API to obtain the info.
	 * @returns Model MPerson create with the data obtained.
	 */
	public extractInfoUserOfApi(response: any): MPerson {

		let client: MClient | null;
		client = new MClient(

			response.cliente.id,
			response.cliente.saldo_tarjeta,
			response.cliente.saldo_premios

		);
		let person = new MPerson(

			response.nombre,
			response.apellido_1,
			response.apellido_2,
			new Date(response.fecha_nacimiento),
			response.email,
			client,
			response.codigo_postal,
			undefined,
			response.direccion,
			response.ciudad,
			response.provincia,
			response.identificacion,
			response.numero_cuenta_bancaria,
			response.telefono,
			response.credit_card,
			response.email_validado,
			response.telefono_validado,
			undefined,
			response.imagen,
		);


		person.iban = response.numero_cuenta_bancaria;
		person.telefono_validado = response.telefono_validado

		this.setUsuario(person)

		return person

	}

	public getNotificaciones() {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}personas/${tokenJWT.idClient}/notificaciones`, httpOptions);
	}

	public updateNotificaciones(tipo: number, via: number) {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		return this.http.put(`${this.URL}personas/${tokenJWT.idClient}/notificaciones?tipoNotificacion=${tipo}&viaNotificacion=${via}`, null, httpOptions);
	}

	getMovimientos() {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		this.movimientosObserver$ = this.http.get(`${this.URL}personas/${tokenJWT.idClient}/movimientos`, httpOptions).pipe(shareReplay());
	}

	/**
	 * Funcion aun por hacer (objectivo poder ingresar dinero)
	 */
	ingresarDinero(cantidad, seguro, bizum): Observable<any> {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		if (bizum == 1) {
			seguro = 1;
		}

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}personas/${tokenJWT.idClient}/ingresoSaldo?cantidad=${cantidad}&seguro=${seguro}&bizum=${bizum}`, httpOptions);
	}

	/**
	 * Funcion aun por hacer (objectivo poder ingresar dinero)
	 */
	bizumConfig(): Observable<any> {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}bizum/config`, httpOptions);
	}

	/**
	 * Funcion aun por hacer (objectivo Poder retirar dinero)
	 */
	retirarDinero(importe, numeroCuentaBanco) {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let body = {
			"importe": importe,
			"numero_cuenta_bancaria": numeroCuentaBanco
		}

		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}personas/${tokenJWT.idClient}/cobro`, body, httpOptions);
	}

	/**
	 * Function for send pin for phone validation
	 * @param telefono
	 * @returns 
	 */
	enviarPintValidarTelefono(telefono) {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let params = {
			telefono
		}

		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}personas/${tokenJWT.idClient}/pin`, JSON.stringify(params), httpOptions);
	}

	validarPinTelefono(pin) {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}personas/${tokenJWT.idClient}/pin-valido?pin=${pin}`, httpOptions);
	}

	validarEmail() {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}personas/${tokenJWT.idClient}/validacion`, null, httpOptions)
	}

	confirmEmail(code) {
		const token = this.authService.loadToken();
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}personas/validar/validate-email?codigo=${code}`, httpOptions);
	}


	deleteAccount(): any {
		const token = this.authService.loadToken();

		if (!token) {
			return new Observable<string>();
		}

		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };

		return this.http.post(`${this.URL}personas/borrar`, null, httpOptions);
	}

	getSeguridad(): any {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		const httpOptions = { headers: this.httpHeaders };
		return this.http.get(`${this.URL}personas/seguridad/${tokenJWT.idClient}/mostrar`, httpOptions);
	}

	setSeguridad(acceso_app, compra_producto, solicitud_transferencia, pin=null): any {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let params = { acceso_app, compra_producto, solicitud_transferencia, pin };

		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}personas/seguridad/${tokenJWT.idClient}/activar`, JSON.stringify(params), httpOptions);
	}

	checkSecurityPin(pin) {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let params = {
			pin,
		}

		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}personas/seguridad/${tokenJWT.idClient}/checkpin`, JSON.stringify(params), httpOptions);
	}

	setSecurityPin(pin) {
		const token = this.authService.loadToken();
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});

		let params = {
			pin,
		}

		const httpOptions = { headers: this.httpHeaders };
		return this.http.post(`${this.URL}personas/seguridad/${tokenJWT.idClient}/cambiarpin`, JSON.stringify(params), httpOptions);
	}

	checkLegal() {
		let token = this.authService.loadToken();
		if (!token) {
			return;
		}
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		
		this.http.get(`${this.URL}personas/legal/comprobar/${tokenJWT.idClient}`, httpOptions).subscribe((res: any) => {
			if (res.status == 200 && res.data == false) {
				let dialogAlert = this.dialog.open(modalLegalComponent, {
					panelClass: 'modal-custom',
					disableClose: true
				});
		
				dialogAlert.afterClosed().subscribe(res => {
					if (res == true)
						this.acceptLegal().subscribe((res:any) => {
							if (res.status == 200) {
								console.log("aceptado");
							}
						}
					)
					this.setlegal();
				})
			}
		});
	}

	acceptLegal() {
		let token = this.authService.loadToken();
		if (!token) {
			return null;
		}
		const tokenJWT = this.authService.decodeToken(token);
		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };
		
		return this.http.get(`${this.URL}personas/legal/aceptar/${tokenJWT.idClient}`, httpOptions);
	}

	uploadImage(file: File): Observable<any> {
		let token = this.authService.loadToken();
		if (!token) {
			return null;
		}

		const formData: FormData = new FormData();
		formData.append('imagen', file);
	
		this.httpHeaders = new HttpHeaders({
			token
		});

		const httpOptions = { headers: this.httpHeaders };
	
		return this.http.post(`${this.URL}personas/perfil/imagen`, formData, httpOptions);
	}

	borrarImagen(): Observable<any> {
		let token = this.authService.loadToken();
		if (!token) {
			return null;
		}

		this.httpHeaders = new HttpHeaders({
			'Content-Type': 'application/json',
			token
		});
		const httpOptions = { headers: this.httpHeaders };

		return this.http.delete(`${this.URL}personas/perfil/imagen`, httpOptions);
	}
}
