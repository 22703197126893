
<!--
<div class="modal">
    <h4 mat-dialog-title>{{data.title}}</h4>
    <div class="curpo" mat-dialog-content [innerHTML]="data.texto"></div>
    <div class="footer">
        <button class="btn ml2 btn-primary" [ngClass]="{'btn-cool': modeGroup}" [mat-dialog-close]="true">{{data.buttonConfirm}}</button>
        <div class="noMostrar" *ngIf="data.showOptionCheck">
            <label class="custom-radio-checkbox">
                <input class="custom-radio-checkbox__input" type="checkbox" name="metodoPago" value="tarjeta"/>
                <span class="custom-radio-checkbox__show custom-radio-checkbox__show--checkbox"></span>
                <span class="custom-radio-checkbox__text">No volver a mostrar</span>
            </label>
        </div>
    </div>
</div>
-->

<div class="share-modal">
    <div class="title-wrapper">
        <span class="title-text">{{data.title}}</span>
        <img class="close-button" src="/assets/images/icons/x-icon.svg" [mat-dialog-close]="true">
    </div>
    <span class="text-label">Comparte via:</span>
    <div class="social-wrapper">
        <share-buttons theme="circles-dark"
        [include]="['facebook', 'email', 'messenger', 'telegram','whatsapp']"
        [showIcon]="true"
        [showText]="false"
        [url]="this.link"
        [description] = "this.descripcion"
        [autoSetMeta]="false">
      </share-buttons>
    </div>
    <span class="text-label">Copiando este enlace: </span>
    <div class="link-wrapper">
        <input type="text" class="share-link" disabled value="{{link}}">
        <share-button 
            button="copy" 
            text="Enlace" 
            theme="modern-dark" 
            [showIcon]="true"
            [showText]="true"
            [description] = "this.descripcion"
            [url]="this.link">
        </share-button>
    </div>
</div>