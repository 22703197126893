<div class="spinner" *ngIf="!load">
    <app-spinner forceTo="component-sized"></app-spinner>
</div>
<div class="manual-play-lottery-wrapper" [style.display]="load ? 'block' : 'none'">

  <div class="manual-play-lottery-container">
      
    <div class="header-list card">
        <div class="numero-loteria-search" [formGroup]="numberForm">
            <input #search type="text" formControlName="num" (input)="newSearchNumber()" placeholder="Numeros disponibles">
            <img src="/assets/images/icons/header/Search.svg" (click)="newSearchNumber()"/>
        </div>
    </div>

    <div class="number-selection">
        
        <cdk-virtual-scroll-viewport #scroll itemSize="15" class="scrollLottery">

            <div class="numbers-list">
                <div class="number-detail" [ngClass]="{'mb-1': last}" *ngFor="let l of searchNumbers; let last = last">
    
                    <div class="number-image">
                        <img class="image" [src]="url" alt="img">
                    </div>
    
                    <div class="number-data">
                        <h3>{{l.numero}}</h3>
                        <p>Quedan {{l.cantidad}}</p>
                    </div>
                    
                    <div class="number-layout">
                        <button class="button-less" (click)="restarDecimo(l)">
                            <img src="/assets/images/icons/minos.svg" alt="img"/>
                        </button>
                        <div class="quantity-label">
                            <span>{{l.cantidad_compra}}</span>
                        </div>
                        <button class="button-plus" (click)="sumarDecimo(l)">
                            <img src="/assets/images/icons/Plus.svg" alt="img">
                        </button>
                    </div>
    
                </div>
				
				<div class="bottom-spinner">
					<mat-progress-spinner class="spinner" *ngIf="spinnerBottomLoadVisibility" [mode]="'indeterminate'" [diameter]="35"> </mat-progress-spinner>
				</div>
            </div>
            


        </cdk-virtual-scroll-viewport>


    </div>

  </div>

</div>
