<div class="play-wrapper">
    <img class="play-game-icon" src="/assets/images/icons/plays-list/icons-list/icono_juego_{{play.idGame}}.png"/>
    <div class="play-details" (click)="detailPlay(play)">
        <div class="play-info">
            <span class="play-game-name">{{play.name}}</span>
            <span class="play-game-status" [ngStyle]="styleState()">
                <span *ngIf="!isSorteado() && play.state != 3">¡En juego!</span>
                <span *ngIf="isSorteado() && play.state != 3">Sorteada</span>
                <span *ngIf="play.state == 3">¡Premiada!</span>
            </span>
        </div>
        <div class="play-bet">

            
            <h4 class="bet-numbers">

                <!-- Apuesta Numeracion -->
                <span *ngFor="let number of play.betsNumbers[0]">
                    <div *ngIf="number < 10 && (play.idGame != 6 && play.idGame != 7 && play.idGame != 8); then addZero else withoutZero"></div>
                    
                    <ng-template #addZero>
                        <span style="margin-right: 5px;">0{{number}}</span>
                    </ng-template>
            
                    <ng-template #withoutZero>
                        <span style="margin-right: 5px;">{{number}}</span>
                    </ng-template>
                </span>

                <!-- Apuesta Estrellas -->
                <span *ngIf="play.idGame == 3">
                    <span *ngFor="let number of play.valueStars[0].estrellas">
                                        
                        <div *ngIf="number < 10; then addZero else withoutZero"></div>
    
                        <ng-template #addZero>
                            <span> 
                                <img class="bet-stars" src="/assets/images/icons/plays-list/star.svg"/>0{{number}} 
                            </span>
                        </ng-template>
                
                        <ng-template #withoutZero>
                            <span> 
                                <img class="bet-stars" src="/assets/images/icons/plays-list/star.svg"/>{{number}} 
                            </span>
                        </ng-template>
                    </span>
                </span>

                <!-- Apuesta Reintegro -->
                <span *ngIf="play.idGame == 1 || play.idGame == 4 || play.idGame == 5">

                    <span *ngIf="play.refund; then refund else waitRefund"></span>

                    <ng-template #refund>
                        <span>R: {{ play.refund }}</span>
                    </ng-template>
                    <ng-template #waitRefund>
                        <span>R: *</span>
                    </ng-template>
                    
                </span>
            </h4>
        </div>
    </div>

    <img class="image-status" src="/assets/images/icons/plays-list/prize_icon.svg" *ngIf="play.state == 3">
    <img class="image-status" src="/assets/images/icons/plays-list/sub_icon.svg" *ngIf="play.abono">
    <img class="image-status" src="/assets/images/icons/plays-list/share_icon.svg" *ngIf="play.link">
    <img class="image-status" src="/assets/images/icons/games/gift-free-bet.svg" *ngIf="play.jugada_regalada && play.jugada_regalada.aceptada != null">
    <img class="image-status gifted-play-status" src="/assets/images/icons/games/gift-free-bet-gifted.svg" *ngIf="play.jugada_regalada && play.jugada_regalada.aceptada == null">
    
    <img class="play-game-details" *ngIf="play.idGame != 6 && play.idGame != 8" src="/assets/images/icons/play-detail/play-details.svg" (click)="openOptions()">
</div>
<div class="actions-play" *ngIf="selected">
    <div class="group-action" *ngIf="play.idGame != 6 && play.idGame != 8 && !play.abono" (click)="abonarse()" >
        <img src="/assets/images/icons/play-detail/abono-play.svg">
        <span class="action-name">Suscribrise</span>
    </div>
    <div class="group-action" *ngIf="play.idGame != 6 && play.idGame != 7 && play.abono" (click)="desabono()">
        <img src="/assets/images/icons/play-detail/abono-play-full.svg">
        <span class="action-name">Desuscribirse</span>
    </div>
    <div class="group-action">
        <img src="/assets/images/icons/play-detail/repeat-play.svg" (click)="repeatPlay()">
        <span class="action-name">Repetir</span>
    </div>
    <div class="group-action" *ngIf="play.idGame == 6 && play.state == 1 && play.jugada_regalada == null && !play.link" (click)="openRegalarJugada()">
        <img src="/assets/images/icons/play-detail/gift.svg">
        <span class="action-name">Regalar</span>
    </div>
</div>