<div class="bottomSheetContainer">
    <div class="formaEnvio card">
        <span>Forma de envio</span>
        <select name="formEnvio" (change)="checkFormaEnvio($event.target.value)" *ngIf="FormasEnvio" >
            <option [value]="formEnvio | json" [selected]="isActiveFormaEnvio(formEnvio)" *ngFor="let formEnvio of FormasEnvio">{{formEnvio.nombre}} ( {{ formEnvio.coste | currency:'EUR' }} )</option>
        </select>

        <div class="footer">
            <button [ngClass]="{'disabled': isLoading}" (click)="updateShipmentForm()" [disabled]="isLoading">Actualizar</button>
        </div>
    </div>
</div>