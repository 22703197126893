import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDg7mxsL4YwMk2RmB7kn0ZzE8mjbi5rVoo",
    authDomain: "miloto.firebaseapp.com",
    databaseURL: "https://miloto.firebaseio.com",
    projectId: "miloto",
    storageBucket: "miloto.appspot.com",
    messagingSenderId: "607964059072",
    appId: "1:607964059072:web:61952f2181bb0c7795a425",
    measurementId: "G-JSSSX754GL"
};

export class FireBase
{
    protected app = initializeApp(firebaseConfig);
    protected analytics = getAnalytics(this.app);
}