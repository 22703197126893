<div class="spinner-wrapper" *ngIf="loading">
    <app-spinner forceTo='component-sized' ></app-spinner>
</div>

<div class="content-list">
    <div class="plays-list">
        <cdk-virtual-scroll-viewport #scroll itemSize="15" class="scroll">

            <ng-container *ngIf="finalPlays?.length > 0">
                <div class="plays-row" *ngFor="let play of finalPlays; let i = index; let last=last" [ngClass]="{mb5: last}">
                    <span class="plays-date" *ngIf="printDate(i)">{{play.date}}</span>
                    <app-play-item-grupo [play]="play" [abono]="abonos" [grupo]="grupo" (dropAbono)="dropAbonoList($event)"></app-play-item-grupo>
                </div>
            </ng-container>
            
            <div class="no-plays" *ngIf="finalPlays?.length == 0 && loading == false">
                <h3 class="no-plays-text">
                    Aquí parece que todavía no hay mucho que ver
                </h3>
                <img class="image" src="/assets/images/icons/plays-list/no-plays.svg"/>
            </div>

            <div class="bottom-spinner">
                <mat-progress-spinner class="spinner" *ngIf="spinnerBottomLoadVisibility" [mode]="'indeterminate'" [diameter]="35"> </mat-progress-spinner>
            </div>

        </cdk-virtual-scroll-viewport>
        <br>
    </div>
    
</div>