import { Component, OnInit } from "@angular/core";
import { GamesApiservice } from "@api/services/games/games-api.service";
import { formsEnvioModel } from "@models/api-models/fromsEnvio.model";
import { BetService } from "@modules/games/bet.service";

@Component({
    selector:'app-forma-envio',
    templateUrl:'./forma-envio.component.html',
    styleUrls:['./forma-envio.component.scss']
})
export class formaEnvioComponent implements OnInit
{
    public FormasEnvio: formsEnvioModel[];

    constructor(private betService: BetService, 
                private gamesApiService: GamesApiservice)
    {
        this.FormasEnvio=[];
    }
    ngOnInit(){
        this.loadFormaEnvio();
    }

    public loadFormaEnvio()
    {
      this.gamesApiService.getFormasEnvio().subscribe((x: formsEnvioModel[])=>this.FormasEnvio.push(...x));
    }

    isActiveFormaEnvio(formaEnvio: formsEnvioModel): boolean
    {
      if(this.betService.sendMode != null)
        return this.betService.sendMode.id == formaEnvio.id
      else
      {
        if(formaEnvio.id == 1)
          return true
      }
      return false
    }

    checkFormaEnvio(formaEnvio: string)
    {
      let formaEnv: formsEnvioModel= JSON.parse(formaEnvio);
      this.betService.setSendMode(formaEnv);
      this.betService.calculateCost();
    }

}