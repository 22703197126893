import { Injectable } from "@angular/core";
// MODELS
import { MPlay } from "../../models/api-models/plays-api.models";
import { CancerPlay } from "@models/api-models/cancer-play.model";

@Injectable({
    providedIn: 'root'
  })
export class PlaysService   {

    constructor() {}


    /**
     * Extract the info of all plays from api
     * @param playsApi
     * @returns Array<MPlay>
     */
    public extractInfoApi(playsApi: Array<any>): Array<MPlay>{
        let plays: Array<MPlay>;
        plays = [];

        playsApi.forEach(play => {
            plays.push(this.extractInfoPlay(play));

        });

        return plays;
    }

	extractInfoCancer(cancer: Array<any>): Array<CancerPlay> {
		return cancer.map((c) => this.tumor(c));
	}

	public tumor(c: any): CancerPlay {

		var jugada, participacion, fecha, importe, imagen, nombre = null;

		if(c.tipo == 'penyas'){
			imagen = c.participacion.producto.penya.imagen
			importe = c.participacion.importe;
			fecha = (new Date(c.participacion.producto.fecha)).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).charAt(0).toUpperCase() +
			(new Date(c.participacion.producto.fecha)).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).slice(1);
			participacion = c.participacion;
			nombre = "Participacion"
		} else {
			jugada = this.extractInfoPlay(c.jugada);
			imagen = `/assets/images/icons/plays-list/icons-list/icono_juego_${jugada.idGame}.png`
			importe = c.jugada.precio
			fecha = (new Date(c.jugada.fecha_sorteo)).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).charAt(0).toUpperCase() +
			(new Date(c.jugada.fecha_sorteo)).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).slice(1);
			nombre = jugada.name
		}

		let play = new CancerPlay(
			c.cliente_id,
			c.tipo,
			fecha,
			importe,
			nombre,
			imagen,
			jugada,
			participacion
		);
		return play;

	}

    /**
     * Extract the info of a play from api
     * @param play
     * @returns MPlay
     */
    public extractInfoPlay(play: any): MPlay{

        const dateOptions = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };

        let date =  (new Date(play['fecha_sorteo']!))
        .toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).charAt(0).toUpperCase() +
        (new Date(play['fecha_sorteo']!)).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: 'numeric'}).slice(1);

        let betsNumbers = [];
        let auxStars = [];
        for(const bet of play['apuestas']){
            betsNumbers.push(bet['numeros']);
            auxStars.push( bet['valores_auxiliares'] );
        }

        let valueStars;
        if(auxStars != null){
            valueStars = auxStars;
        }

        let refund = play['valores_jugada'];
        let loteriaSerieFraccion = null;

        let refundPlay;
        if(refund != null){
            refundPlay = refund['reintegro'] || refund['clave'];
            if(refund['serie'] && refund['fraccion'])
                loteriaSerieFraccion = {serie: refund['serie'], fraccion: refund['fraccion'] }
        }

        let relacionadas = play["relacionadas"]

        let jugada_regalada = play['jugada_regalada']

        let link = play['link'];

        let abono = play['abono'];

		let validated = play['validada'];

        let shared = false;
        if(play['compartida'] != null){
            shared = true;
        }

        let guinda = false;
        if(play['guinda'] != null){
            guinda = true;
        }
        
        let state = play['estado'];
        if(play['simulacion'] && Number(play['simulacion']['premio']) > 0){
            state = 3;
        }

        let playAux;

        if(play.producto){
            playAux = new MPlay(
                play['id'],
                date,
                '',
                '',
                state,
                betsNumbers,
                valueStars,
                refundPlay,
                shared,
                false,
                play['precio'],
                play['pedido_id'],
                play['juego_id'],
                play['simulacion'],
                relacionadas,
                jugada_regalada,
                link,
                abono,
                null
            );
            playAux.producto = true;
            playAux.miniatura = play.miniaturas[0]
            playAux.nombre_producto = play.nombre_producto
        } else {
            playAux = new MPlay(
                play['id'],
                date,
                play['juego']['nombre'],
                play['juego']['imagen'],
                state,
                betsNumbers,
                valueStars,
                refundPlay,
                shared,
                false,
                play['precio'],
                play['pedido_id'],
                play['juego_id'],
                play['simulacion'],
				play['semanal'],
				play['multiple'],
				play['juego']['precio_guinda'],
                relacionadas,
                jugada_regalada,
                link,
                abono,
                play['fecha_sorteo']+" "+play['sorteo']['hora_sorteo'],
                play['pedido']
            );

            playAux.valuesLottery = loteriaSerieFraccion
			play.guinda_price = play['juego']['precio_guinda'];
			playAux.guinda_price = play['juego']['precio_guinda'];
        }

		playAux.nombre = play.nombre;
		play.semanal = play['semanal'];
		play.multiple = play['multiple'];
		playAux.semanal = play['semanal'];
		playAux.multiple = play['multiple'];
		playAux.validated = validated;

		if(guinda)
			playAux.guinda = true;
        return playAux;

    }

}
