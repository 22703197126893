<div class="bottomSheetContainer" *ngIf="userDates">
	<div class="button" (click)="dismiss()">
		<div class="btn-ln">
			<div class="line" [ngClass]="{'line-cool': false}">
  
			</div>
			<h4></h4>
		</div>
	</div>
    
    <div class="header">
        <h2>Confirma o establece tu dirección de envío para poder recibir tu décimo en tu casa cómodamente.</h2>
    </div>

    <form class="form-direccion">
        <div class="body-form-direccion">
            <input class="form-comtrol-direccion" type="text" name="direccion" [(ngModel)]="userDates.direccion" value="" placeholder="Dirección"/>
            <input class="form-comtrol-direccion" type="text" name="codigo_postal" [(ngModel)]="userDates.codigo_postal" placeholder="Código Postal"/>
            <input class="form-comtrol-direccion" type="text" name="ciudad" [(ngModel)]="userDates.ciudad" placeholder="Ciudad"/>
            <input class="form-comtrol-direccion" type="text" name="provincia" [(ngModel)]="userDates.provincia" value="" placeholder="Provincia"/>
            <input class="form-comtrol-direccion" type="text" name="telefono" [(ngModel)]="userDates.telefono" value="" placeholder="Teléfono"/>
        </div>

        <div class="footer-form-direccion">
            <div class="divider">
                <hr>
            </div>
			<div class="bottom-butons">
				<button class="btn btn-cancel" (click)="cancel()" type="button">Cancelar</button>
				<button class="btn btn-confirm" (click)="confirm()" [disabled]="addrFlag" type="submit">Continuar</button>
			</div>
        </div>
    </form>
</div>